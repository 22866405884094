import { Checkbox } from 'antd';
import classNames from 'classnames';

import { ColorData } from '../../constants/color.constants';
import Gauge from '../Gauge';

export type ColorOptionProps = {
  data: ColorData;
  id: string;
  selected: boolean;
  onSelect: (id: string) => void;
};

const ColorOption: React.FunctionComponent<ColorOptionProps> = ({
  data,
  id,
  selected,
  onSelect,
}: ColorOptionProps): JSX.Element => {
  const containerClasses = classNames({
    'w-[297px] h-[245px] rounded-lg border border-neutral-300 flex flex-col items-center overflow-hidden cursor-pointer':
      true,
    'border-primary-500': selected,
  });

  const handleSelect = (): void => {
    onSelect(id);
  };
  return (
    <div className={containerClasses} onClick={handleSelect}>
      <Checkbox className="self-end !-mt-1" checked={selected} />
      <span className="text-base font-medium">{data.name}</span>
      <Gauge
        gaugeColors={data.colors}
        nutrientRange={{ minimum: 0, veryLow: 0, low: 0, high: 0, veryHigh: 0, maximum: 0 }}
      />
    </div>
  );
};

export default ColorOption;
