import { SoilNutrient } from './nutrient.constants';

// On the gauge, the range is divided into 5 parts
// Very Low: Between minimum and veryLow
// Low: Between veryLow and low
// Optimum: Between low and high
// High: Between high and veryHigh
// Very High: Between veryHigh and maximum
export type NutrientRange = {
  minimum: number;
  veryLow: number;
  low: number;
  high: number;
  veryHigh: number;
  maximum: number;
  inverse?: boolean;
};

export type NutrientRangeMapping = {
  [key in SoilNutrient]: NutrientRange | null;
};

export type RangeData = {
  name: string;
  rangeMapping: NutrientRangeMapping;
};

export type RangeDataMapping = {
  [key: string]: RangeData;
};

export const OMAFRA_RANGE_MAPPING: NutrientRangeMapping = {
  organicMatter: {
    minimum: 0,
    veryLow: 1,
    low: 2.5,
    high: 5,
    veryHigh: 9,
    maximum: 50,
  },
  cationExchangeCapacity: {
    minimum: 0,
    veryLow: 5,
    low: 10,
    high: 18,
    veryHigh: 25,
    maximum: 50,
  },
  potentialOfHydrogen: {
    minimum: 0,
    veryLow: 5,
    low: 6,
    high: 7,
    veryHigh: 8,
    maximum: 14,
  },
  bufferPotentialOfHydrogen: {
    minimum: 0,
    veryLow: 5.5,
    low: 6,
    high: 6.5,
    veryHigh: 7,
    maximum: 7.5,
  },
  availablePhosphorus: {
    minimum: 0,
    veryLow: 15,
    low: 25,
    high: 40,
    veryHigh: 60,
    maximum: 300,
  },
  totalPhosphorus: null,
  phosphorus: null,
  bicarbonate: {
    minimum: 0,
    veryLow: 10,
    low: 25,
    high: 40,
    veryHigh: 60,
    maximum: 300,
  },
  potassium: {
    minimum: 0,
    veryLow: 65,
    low: 125,
    high: 180,
    veryHigh: 300,
    maximum: 700,
  },
  calcium: {
    minimum: 0,
    veryLow: 1500,
    low: 3000,
    high: 4000,
    veryHigh: 5500,
    maximum: 7500,
  },
  magnesium: {
    minimum: 0,
    veryLow: 75,
    low: 150,
    high: 300,
    veryHigh: 450,
    maximum: 2500,
  },
  sulfur: {
    minimum: 0,
    veryLow: 5,
    low: 10,
    high: 20,
    veryHigh: 35,
    maximum: 100,
  },
  sodium: {
    minimum: 0,
    veryLow: 5,
    low: 10,
    high: 20,
    veryHigh: 30,
    maximum: 250,
  },
  boron: {
    minimum: 0,
    veryLow: 0.5,
    low: 1,
    high: 2,
    veryHigh: 4,
    maximum: 25,
  },
  chlorine: null,
  copper: {
    minimum: 0,
    veryLow: 0.5,
    low: 1,
    high: 1.8,
    veryHigh: 3,
    maximum: 10,
  },
  iron: {
    minimum: 0,
    veryLow: 10,
    low: 20,
    high: 40,
    veryHigh: 60,
    maximum: 250,
  },
  manganese: {
    minimum: 0,
    veryLow: 10,
    low: 20,
    high: 40,
    veryHigh: 75,
    maximum: 250,
  },
  zinc: {
    minimum: 0,
    veryLow: 1.5,
    low: 3,
    high: 6,
    veryHigh: 7.5,
    maximum: 50,
  },
  mehlichThreeAluminum: {
    minimum: 0,
    veryLow: 100,
    low: 200,
    high: 700,
    veryHigh: 1400,
    maximum: 5000,
  },
  potassiumMagnesiumRatio: {
    minimum: 0,
    veryLow: 0.1,
    low: 0.2,
    high: 0.35,
    veryHigh: 0.6,
    maximum: 10,
  },
  potassiumPercentage: {
    minimum: 0,
    veryLow: 0.5,
    low: 2,
    high: 5,
    veryHigh: 10,
    maximum: 30,
  },
  magnesiumPercentage: {
    minimum: 0,
    veryLow: 5,
    low: 10,
    high: 18,
    veryHigh: 25,
    maximum: 50,
  },
  calciumPercentage: {
    minimum: 0,
    veryLow: 40,
    low: 60,
    high: 80,
    veryHigh: 90,
    maximum: 100,
  },
  sodiumPercentage: {
    minimum: 0,
    veryLow: 0.25,
    low: 0.5,
    high: 1,
    veryHigh: 2,
    maximum: 25,
  },
  hydrogenPercentage: {
    minimum: 0,
    veryLow: 2,
    low: 5,
    high: 15,
    veryHigh: 30,
    maximum: 75,
  },
};

export const AGRONOMIC_SOLUTIONS_RANGE_MAPPING: NutrientRangeMapping = {
  organicMatter: {
    minimum: 0,
    veryLow: 0.5,
    low: 1.5,
    high: 3.5,
    veryHigh: 5,
    maximum: 40,
  },
  cationExchangeCapacity: {
    minimum: 1,
    veryLow: 7,
    low: 12,
    high: 18,
    veryHigh: 25,
    maximum: 60,
  },
  potentialOfHydrogen: {
    minimum: 3.5,
    veryLow: 5.7,
    low: 6.1,
    high: 6.5,
    veryHigh: 7.1,
    maximum: 9.5,
  },
  bufferPotentialOfHydrogen: {
    minimum: 5,
    veryLow: 6.3,
    low: 6.5,
    high: 6.7,
    veryHigh: 6.9,
    maximum: 8,
  },
  availablePhosphorus: {
    minimum: 0,
    veryLow: 8,
    low: 15,
    high: 20,
    veryHigh: 30,
    maximum: 250,
  },
  totalPhosphorus: {
    minimum: 0,
    veryLow: 12,
    low: 25,
    high: 45,
    veryHigh: 60,
    maximum: 500,
  },
  phosphorus: {
    minimum: 0,
    veryLow: 8,
    low: 15,
    high: 20,
    veryHigh: 30,
    maximum: 250,
  },
  bicarbonate: null,
  potassium: {
    minimum: 20,
    veryLow: 120,
    low: 160,
    high: 200,
    veryHigh: 240,
    maximum: 900,
  },
  calcium: {
    minimum: 50,
    veryLow: 1200,
    low: 1900,
    high: 2750,
    veryHigh: 3300,
    maximum: 10000,
  },
  magnesium: {
    minimum: 25,
    veryLow: 150,
    low: 300,
    high: 450,
    veryHigh: 600,
    maximum: 2500,
  },
  sulfur: {
    minimum: 0,
    veryLow: 6,
    low: 12,
    high: 18,
    veryHigh: 24,
    maximum: 125,
  },
  sodium: {
    minimum: 0,
    veryLow: 65,
    low: 110,
    high: 150,
    veryHigh: 200,
    maximum: 2500,
  },
  boron: {
    minimum: 0,
    veryLow: 0.3,
    low: 0.7,
    high: 1.2,
    veryHigh: 2,
    maximum: 20,
  },
  chlorine: null,
  copper: {
    minimum: 0,
    veryLow: 0.3,
    low: 0.8,
    high: 1.2,
    veryHigh: 2.5,
    maximum: 15,
  },
  iron: {
    minimum: 0,
    veryLow: 5,
    low: 10,
    high: 15,
    veryHigh: 25,
    maximum: 250,
  },
  manganese: {
    minimum: 0,
    veryLow: 4,
    low: 8,
    high: 12,
    veryHigh: 25,
    maximum: 250,
  },
  zinc: {
    minimum: 0,
    veryLow: 0.5,
    low: 1,
    high: 3,
    veryHigh: 5,
    maximum: 50,
  },
  mehlichThreeAluminum: null,
  potassiumMagnesiumRatio: null,
  potassiumPercentage: {
    minimum: 0,
    veryLow: 1.3,
    low: 2,
    high: 6,
    veryHigh: 8,
    maximum: 100,
  },
  magnesiumPercentage: {
    minimum: 0,
    veryLow: 9,
    low: 12,
    high: 18.5,
    veryHigh: 25,
    maximum: 100,
  },
  calciumPercentage: {
    minimum: 0,
    veryLow: 56,
    low: 62,
    high: 78,
    veryHigh: 82,
    maximum: 100,
  },
  sodiumPercentage: {
    minimum: 0,
    veryLow: 0.2,
    low: 0.4,
    high: 1,
    veryHigh: 2,
    maximum: 100,
  },
  hydrogenPercentage: {
    minimum: 0,
    veryLow: 0.1,
    low: 1,
    high: 7.5,
    veryHigh: 15,
    maximum: 100,
    inverse: true,
  },
};

export const DEFAULT_RANGE_MAPPING: RangeDataMapping = {
  defaultOntario: {
    name: 'Default Ontario',
    rangeMapping: OMAFRA_RANGE_MAPPING,
  },
  defaultAgronomicSolutions: {
    name: 'Default Agronomic',
    rangeMapping: AGRONOMIC_SOLUTIONS_RANGE_MAPPING,
  },
};
