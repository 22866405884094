import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { VerticalLine, Pen, Download, Print, Custom } from '../../assets/Icon';
import { RangeDataMapping } from '../../constants/range.constants';
import { selectSelectedRange, setSelectedRange } from '../../features/customizeData.slice';
import { clearFileData } from '../../features/fileData.slice';
import { setToggle } from '../../features/headerSlice';
import { clearNutrientData } from '../../features/nutrientData.slice';
import { useRouteType } from '../../hooks/hooks';
import { RootState } from '../../store';
import Button from '../Button';
import Dropdown from '../Dropdown';
import NavBar from '../NavBar';
import Toggle from '../Toggle';

type HeaderProps = {
  logo?: JSX.Element;
  icon?: JSX.Element;
  btnIcon?: JSX.Element;
  onDownloadclick?: () => void;
  onPrintClick?: () => void;
  dropdownOptions?: RangeDataMapping;
};

const Header: React.FC<HeaderProps> = ({
  logo,
  icon,
  onDownloadclick,
  onPrintClick,
  dropdownOptions,
}) => {
  const content = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let layoutType = useRouteType();
  const range = useSelector((state: RootState) => state?.headerDataStore?.isToggle);
  const selectedRange = useSelector(selectSelectedRange);

  const createAnother = (): void => {
    dispatch(clearNutrientData());
    dispatch(clearFileData());
  };

  const handleRangeChange = (id: string): void => {
    dispatch(setSelectedRange(id));
  };

  return (
    <header className="print:hidden">
      {layoutType === 'default' && (
        <>
          <div className="bg-header h-12 w-full flex">
            <div className="uppercase flex items-center justify-end h-full container mx-auto">
              <div>{logo}</div>
            </div>
          </div>
          <div className="border-b flex items-center border-neutral-300 px-24 shadow-md">
            <div className="flex items-center container mx-auto h-24 justify-between">
              <span>
                <Link to="/">{icon}</Link>
              </span>
              <NavBar />
            </div>
          </div>
        </>
      )}
      {layoutType === 'custom' && (
        <>
          <div className="border-b flex items-center border-neutral-300 px-24 shadow-md">
            <div className="flex items-center container mx-auto h-20">
              <span className="mr-2">
                <Link to="/">{icon}</Link>
              </span>
              <span className="mr-2">
                <VerticalLine />
              </span>
              <span className="font-roboto text-xl font-light">Customize</span>
            </div>
            <div className="text-sm">
              <Button
                className="min-w-[205px] text-sm"
                variant="contained"
                color="primary"
                btnClick={() => navigate('/snapshot')}
              >
                Finish customizing
              </Button>
            </div>
          </div>
        </>
      )}
      {layoutType === 'snapshot' && (
        <>
          <div
            ref={content}
            className="border-b flex items-center border-neutral-300 px-24 shadow-md"
          >
            <div className="flex items-center container mx-auto h-20">
              <span className="mr-2">
                <Link to="/">{icon}</Link>
              </span>
            </div>
            <div className="flex items-center justify-center">
              <span className="w-40 pt-1">
                <Link to="/" onClick={createAnother}>
                  <span className="flex items-center font-bold text-primary-700 cursor-pointer">
                    <Pen />
                    <span className="uppercase text-sm ml-2">Create Another</span>
                  </span>
                </Link>
              </span>
              <span className="mr-5">
                <VerticalLine height={45} />
              </span>
              <span className="flex gap-5">
                <Button
                  variant="outlined"
                  color="primary"
                  leftIcon={<Print />}
                  btnClick={onPrintClick}
                >
                  Print
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  rightIcon={<Download color={'#fff'} />}
                  btnClick={onDownloadclick}
                >
                  Download
                </Button>
              </span>
            </div>
          </div>
          <div className="flex px-24 justify-between">
            <div className="flex items-center border-r">
              <Link to="/customize/attributes">
                <div className="flex items-center pr-8">
                  <Button
                    className="font-roboto text-sm font-bold"
                    variant="text"
                    color="primary"
                    leftIcon={<Custom />}
                  >
                    Customize
                  </Button>
                </div>
              </Link>
            </div>
            <div className="flex items-center">
              {dropdownOptions && (
                <Dropdown
                  options={Object.entries(dropdownOptions).map(([id, option]) => ({
                    label: option.name,
                    value: id,
                  }))}
                  title="Ranges"
                  value={selectedRange}
                  onChange={handleRangeChange}
                />
              )}

              <div className="flex items-center ml-8">
                <Toggle checked={range} handleToggle={() => dispatch(setToggle(!range))} />
                <span className="text-primary-700 font-medium text-base">Show ranges</span>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
