import { differenceInSeconds } from 'date-fns';
import { BrowserRouter as Router } from 'react-router-dom';

import { MIXPANEL_EVENTS } from './constants/analytics.constants';
import AppContainer from './pages/AppContainer';
import Analytics from './utils/analytics.utils';

let loadTime = new Date();

window.onload = () => {
  Analytics.trackEvent(MIXPANEL_EVENTS.GENERAL.OPENED_THE_WEB_APP);
  console.log('loaded');
  return null;
};

window.onbeforeunload = () => {
  const now = new Date();
  const timeOnApp = differenceInSeconds(now, loadTime);
  Analytics.trackEvent(MIXPANEL_EVENTS.GENERAL.CLOSED_THE_WEB_APP, {
    timeOnApp,
  });
  return null;
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContainer />
    </Router>
  );
};

export default App;
