import React from 'react';
import classNames from 'classnames';

export type ButtonProps = {
  variant: 'contained' | 'outlined' | 'text';
  color: 'primary' | 'warning' | 'error';
  className?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  btnClick?: () => void;
  children?: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  hidden?: boolean;
  type?: 'button' | 'submit' | 'reset';
  value?: string;
};

const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  className,
  btnClick,
  children,
  disabled,
  hidden,
  variant = 'contained',
  leftIcon,
  rightIcon,
  type,
  value,
}) => {
  const buttonClasses = classNames(
    {
      'border border-primary-700 py-3 px-7 rounded font-bold uppercase text-base z-10 flex items-center gap-2.5':
        variant === 'contained',
    },
    {
      'border border-primary-700 py-3 px-7 rounded font-bold text-primary-700 uppercase text-base z-10 flex items-center gap-2.5 hover:bg-primary-50':
        variant === 'outlined',
    },
    {
      'text-primary-700 uppercase text-sm z-10 font-bold flex items-center gap-2':
        variant === 'text',
    },
    /**for primary color for all three variants */
    {
      'bg-primary-700 text-neutral-50 hover:bg-primary-800':
        color === 'primary' && variant === 'contained',
    },
    {
      'border-primary-500 text-primary-500 hover:bg-primary-100':
        color === 'primary' && variant === 'outlined',
    },
    {
      'text-primary-500': color === 'primary' && variant === 'text',
    },
    /**for warning color for all three variants */
    {
      'bg-yellow-200 border border-yellow-900 text-yellow-900 hover:bg-yellow-400':
        color === 'warning' && variant === 'contained',
    },
    {
      'border-yellow-500 text-yellow-500 hover:bg-yellow-200':
        color === 'warning' && variant === 'outlined',
    },
    {
      'text-yellow-500': color === 'warning' && variant === 'text',
    },
    /**for error color for all three variants */
    {
      'bg-red-100 border border-red-600 text-red-900 hover:bg-red-200':
        color === 'error' && variant === 'contained',
    },
    {
      'border-red-600 text-red-900 hover:bg-red-100': color === 'error' && variant === 'outlined',
    },
    {
      'text-red-600': color === 'error' && variant === 'text',
    },
    /**for disabled class for all three variants */
    {
      'opacity-50 cursor-not-allowed': disabled,
    },
    /**for hiding class for all three variants */
    {
      hidden: hidden,
    },
  );

  return (
    <button
      value={value}
      type={type}
      className={`${buttonClasses} ${className}`}
      onClick={btnClick}
      disabled={disabled}
    >
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </button>
  );
};

export default Button;
