import classNames from 'classnames';

type footerProps = {
  className?: string;
};

const Footer = ({ className }: footerProps) => {
  const footerClass = classNames('flex gap-4 justify-center pb-12 pt-16 print:hidden', className);

  return (
    <footer className={footerClass}>
      <span className="px-4 border-r-[1px] border-neutral-600">
        <a
          className="text-sm text-primary-700 font-normal"
          href="https://www.deveronapp.com/terms.html"
        >
          Terms
        </a>
      </span>
      <span>
        <a
          className="text-sm text-primary-700 font-normal"
          href="https://www.deveronapp.com/privacy.html"
        >
          Privacy Policy
        </a>
      </span>
    </footer>
  );
};

export default Footer;
