// Missing:
// All: SALT/Sol Salt/SolubleSalts, NO3N/NO3 (A&L/Agronomic Solutions/Frontier Labs, this is my assumption)
// A&L: NH3N, AL_M3, ENR
// Agronomic Solutions: hMg, Excess Lime, Sub soil 1, Sub Soil 2, TKN_PPM, NH4, SAR, SAR_NA, SAR_MG, SAR_CA
// Frontier Labs: PDesc, KDesc, LimeTo65, LimeTo69, Basesat

const SUPPORTED_NUTRIENTS = [
  'organicMatter',
  'cationExchangeCapacity',
  'potentialOfHydrogen',
  'bufferPotentialOfHydrogen',
  'availablePhosphorus',
  'totalPhosphorus',
  'phosphorus',
  'bicarbonate',
  'potassium',
  'calcium',
  'magnesium',
  'sulfur',
  'sodium',
  'boron',
  'chlorine',
  'copper',
  'iron',
  'manganese',
  'zinc',
  'mehlichThreeAluminum',
  'potassiumMagnesiumRatio',
  'potassiumPercentage',
  'calciumPercentage',
  'magnesiumPercentage',
  'hydrogenPercentage',
  'sodiumPercentage',
] as const;
export type SoilNutrient = typeof SUPPORTED_NUTRIENTS[number];

export type SoilNutrientData = {
  aliases: string[];
  label?: string;
};

export type LabResultMapping = {
  [key in SoilNutrient]: SoilNutrientData | null;
};

export const UNIVERSIAL_RESULT_MAPPING: LabResultMapping = {
  organicMatter: {
    aliases: ['OM'],
  },
  cationExchangeCapacity: {
    aliases: ['CEC'],
  },
  potentialOfHydrogen: {
    aliases: ['pH', 'PH'],
    label: 'pH',
  },
  bufferPotentialOfHydrogen: {
    aliases: ['BUFF', 'BpH'],
    label: 'BpH',
  },
  availablePhosphorus: {
    // Right now, POne has to come before P1 because Frontier Labs and Midwest both use P1 (Frontier uses it for something we don't support)
    // so we want to make sure we look for POne first and not overwrite it with the P1 column
    aliases: ['p_1', 'Bray P1', 'POne', 'P1'],
    label: 'P1',
  },
  totalPhosphorus: {
    aliases: ['p_2', 'Bray P2', 'PTwo', 'P2'],
    label: 'P2',
  },
  phosphorus: {
    aliases: ['P'],
  },
  bicarbonate: {
    aliases: ['BICARB', 'Bicarb'],
    label: 'Bicarb',
  },
  potassium: {
    aliases: ['K'],
  },
  calcium: {
    aliases: ['CA', 'Ca'],
    label: 'Ca',
  },
  magnesium: {
    aliases: ['MG', 'Mg'],
    label: 'Mg',
  },
  sulfur: {
    aliases: ['S'],
  },
  sodium: {
    aliases: ['NA', 'Na'],
    label: 'Na',
  },
  zinc: {
    aliases: ['ZN', 'Zn'],
    label: 'Zn',
  },
  manganese: {
    aliases: ['MN', 'Mn'],
    label: 'Mn',
  },
  iron: {
    aliases: ['FE', 'Fe'],
    label: 'Fe',
  },
  copper: {
    aliases: ['CU', 'Cu'],
    label: 'Cu',
  },
  boron: {
    aliases: ['B'],
  },
  chlorine: {
    aliases: ['CL', 'Cl'],
    label: 'Cl',
  },
  mehlichThreeAluminum: {
    aliases: ['AL_M3'],
    label: 'AL_M3',
  },
  potassiumMagnesiumRatio: {
    aliases: ['KMG'],
    label: 'K/Mg',
  },
  potassiumPercentage: {
    aliases: ['k_percent', 'PctK', 'Percent K'],
    label: 'K %',
  },
  magnesiumPercentage: {
    aliases: ['mg_percent', 'PctMg', 'Percent Mg'],
    label: 'Mg %',
  },
  calciumPercentage: {
    aliases: ['ca_percent', 'PctCa', 'Percent Ca'],
    label: 'Ca %',
  },
  hydrogenPercentage: {
    aliases: ['h_percent', 'PctH', 'Percent H'],
    label: 'H %',
  },
  sodiumPercentage: {
    aliases: ['na_percent', 'PctNa', 'Percent Na'],
    label: 'Na %',
  },
};

export const DEFAULT_NUTRIENTS: SoilNutrient[] = [
  'organicMatter',
  'potentialOfHydrogen',
  'bicarbonate',
  'totalPhosphorus',
  'potassium',
  'calcium',
  'magnesium',
  'potassiumPercentage',
  'magnesiumPercentage',
  'calciumPercentage',
];
