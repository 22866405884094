import { format, parse } from 'date-fns';
import { clamp } from 'lodash';
import { GaugeSection } from '../constants/gauge.constants';
import { InfoKey, INFO_MAPPING } from '../constants/parsing.constants';
import { NutrientRange } from '../constants/range.constants';
import { SnapshotInfoFormat } from '../features/snapshotInfoSlice';
import { CsvResult } from '../utils/nutrient.utils';

// Used to replace all unsafe file name characters
const FILE_NAME_REGEX = /([^\w\d\-_ ])/g;

export const getGaugeAngle = (
  value: number,
  valueSection: GaugeSection,
  rangeData: NutrientRange,
) => {
  // Min: 0
  // Max: Math.PI (180 degrees)

  const sectionAngleWidth = Math.PI / 5;
  let sectionAngleMin = 0;
  let sectionValueMin = 0;
  let sectionValueMax = 0;

  switch (valueSection) {
    case 'veryLow':
      sectionAngleMin = 0;
      sectionValueMin = rangeData.minimum;
      sectionValueMax = rangeData.veryLow;
      break;
    case 'low':
      sectionAngleMin = sectionAngleWidth;
      sectionValueMin = rangeData.veryLow;
      sectionValueMax = rangeData.low;
      break;
    case 'optimum':
      sectionAngleMin = sectionAngleWidth * 2;
      sectionValueMin = rangeData.low;
      sectionValueMax = rangeData.high;
      break;
    case 'high':
      sectionAngleMin = sectionAngleWidth * 3;
      sectionValueMin = rangeData.high;
      sectionValueMax = rangeData.veryHigh;
      break;
    case 'veryHigh':
      sectionAngleMin = sectionAngleWidth * 4;
      sectionValueMin = rangeData.veryHigh;
      sectionValueMax = rangeData.maximum;
      break;
  }

  const sectionValueWidth = sectionValueMax - sectionValueMin;
  const sectionValueOffset = value - sectionValueMin;
  const percentOfSection = clamp(sectionValueOffset / sectionValueWidth, 0, 1);
  const angleOffset = sectionAngleWidth * percentOfSection;

  return sectionAngleMin + angleOffset;
};

export const getGaugeSection = (value: number, ranges: NutrientRange): GaugeSection => {
  if (value < ranges.veryLow) {
    return 'veryLow';
  } else if (value < ranges.low) {
    return 'low';
  } else if (value < ranges.high) {
    return 'optimum';
  } else if (value < ranges.veryHigh) {
    return 'high';
  } else {
    return 'veryHigh';
  }
};

export const parseLabDate = (
  labDate: string | undefined,
  type: 'parsed' | 'input',
  columnKey: string = '',
): Date | null => {
  if (!labDate) {
    return null;
  }
  // Hardcoded 'hack' to determine formate for Date column from Midwest labs. Will include this is individual lab mappings in the future.
  const pattern =
    type === 'parsed' ? (columnKey === 'Date' ? 'M/dd/yy' : 'yyyyMMdd') : 'yyyy-MM-dd';
  return parse(labDate, pattern, new Date());
};

export const formatLabDate = (labDate: Date | null, type: SnapshotInfoFormat): string => {
  if (!labDate) {
    return '';
  }
  const pattern = type === 'display' ? 'MM/dd/yyyy' : 'yyyy-MM-dd';
  return format(labDate, pattern);
};

export const formatFileName = (
  growerName: string,
  farmName: string,
  dateReported: string,
): string => {
  //const suffix = dateReported.length > 0 ? `_${dateReported}` : '';
  const suffix = '';
  const safeGrowerName = growerName.replace(FILE_NAME_REGEX, '');
  const safeFarmName = farmName.replace(FILE_NAME_REGEX, '');
  if (safeGrowerName.length > 0 && safeFarmName.length > 0) {
    return `${safeGrowerName}_${safeFarmName}`;
  } else if (safeGrowerName.length > 0) {
    return `${safeGrowerName}${suffix}`;
  } else if (safeFarmName.length > 0) {
    return `${safeFarmName}${suffix}`;
  } else {
    return `Snapshot${suffix}`;
  }
};

export const getInfoMapping = (firstRow: CsvResult): Map<InfoKey, string> => {
  const infoMapping: Map<InfoKey, string> = new Map();
  for (const [key, columns] of Object.entries(INFO_MAPPING)) {
    for (const column of columns) {
      if (firstRow[column]) {
        infoMapping.set(key as InfoKey, column);
        break;
      }
    }
  }
  return infoMapping;
};
