import { isNil, uniqueId } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Add, ArrowLeft, ArrowRight } from '../../../../assets/Icon';
import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import Hero from '../../../../components/Hero';
import { CUSTOM_HERO_CONSTANTS } from '../../../../constants/heroConstants';
import { setSelectedRange } from '../../../../features/customizeData.slice';
import BackButton from '../BackButton';
import { selectCustomRanges, upsertCustomRange } from '../../../../features/rangeData.slice';
import {
  DEFAULT_RANGE_MAPPING,
  OMAFRA_RANGE_MAPPING,
  RangeData,
} from '../../../../constants/range.constants';
import RangeOption from '../../../../components/RangeOption';
import { selectSelectedRange } from '../../../../features/customizeData.slice';
import classNames from 'classnames';

const Range = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedRange = useSelector(selectSelectedRange);
  const customRanges = useSelector(selectCustomRanges);
  const [newRange, setNewRange] = useState<RangeData | null>(null);

  const dividerClasses = classNames({
    'mx-8': true,
    'my-12': !isNil(newRange),
    'mt-12': isNil(newRange),
  });

  const handleBack = () => {
    navigate('/customize/attributes');
  };

  const handleNext = () => {
    navigate('/customize/colors');
  };

  const handleSaveAndExit = () => {
    navigate('/snapshot');
  };

  const handleCreateTable = (id: string, range: RangeData) => {
    const newId = uniqueId();
    dispatch(upsertCustomRange({ id: newId, range }));
    dispatch(setSelectedRange(newId));
    setNewRange(null);
  };

  const handleEditTable = (id: string, range: RangeData) => {
    dispatch(upsertCustomRange({ id, range }));
  };

  const handleAddTable = () => {
    setNewRange({
      name: 'Create New Range',
      rangeMapping: OMAFRA_RANGE_MAPPING,
    });
  };

  const handleCancelCreateNew = () => {
    setNewRange(null);
  };

  const handleSelect = (id: string) => {
    dispatch(setSelectedRange(id));
  };

  return (
    <div className="w-full p-4">
      <BackButton />
      <div className="max-w-[1024px] mx-auto">
        <Hero
          title={CUSTOM_HERO_CONSTANTS.RANGE.TITLE}
          text={CUSTOM_HERO_CONSTANTS.RANGE.DESCRIPTION}
        />
        <div className="flex flex-col gap-12">
          {Object.entries(DEFAULT_RANGE_MAPPING).map(([id, range]) => (
            <RangeOption
              key={id}
              id={id}
              mode="readonly"
              range={range}
              selected={selectedRange === id}
              onSelect={handleSelect}
            />
          ))}
          {Object.entries(customRanges).map(([id, range]) => (
            <RangeOption
              key={id}
              id={id}
              mode="edit"
              range={range}
              selected={selectedRange === id}
              onSave={handleEditTable}
              onSelect={handleSelect}
            />
          ))}
        </div>
        <Divider className={dividerClasses} />
        {isNil(newRange) ? (
          <Button
            className="p-3 ml-8"
            variant="text"
            color="primary"
            leftIcon={<Add />}
            btnClick={handleAddTable}
          >
            Create new range
          </Button>
        ) : (
          <RangeOption
            id="new-range"
            mode="create"
            range={newRange}
            onCancel={handleCancelCreateNew}
            onSave={handleCreateTable}
          />
        )}
        <div className="flex items-center justify-between gap-11 my-14 mx-4">
          <div>
            <Button variant="text" color="primary" leftIcon={<ArrowLeft />} btnClick={handleBack}>
              Back: Attributes
            </Button>
          </div>
          <div className="flex gap-11">
            <Button
              className="w-[161px] h-[43px] font-roboto text-sm	font-bold"
              variant="contained"
              color="primary"
              btnClick={handleSaveAndExit}
            >
              Save and Exit
            </Button>
            <Button variant="text" color="primary" rightIcon={<ArrowRight />} btnClick={handleNext}>
              NEXT: COLORS
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Range;
