import classNames from 'classnames';
import { useState } from 'react';
import { Edit } from '../../assets/Icon';

import { NutrientRangeMapping, RangeData } from '../../constants/range.constants';
import Button from '../Button';
import RangeTable from '../RangeTable';

export type RangeOptionProps = {
  id: string;
  mode: 'readonly' | 'edit' | 'create';
  range: RangeData;
  selected?: boolean;
  onCancel?: () => void;
  onSave?: (key: string, range: RangeData) => void;
  onSelect?: (key: string) => void;
};

const RangeOption: React.FunctionComponent<RangeOptionProps> = ({
  id,
  mode,
  range,
  selected,
  onCancel,
  onSave,
  onSelect,
}): JSX.Element => {
  const [editing, setEditing] = useState<boolean>(mode === 'create');
  const [rangeName, setRangeName] = useState<string>(range.name);
  const [currentMapping, setCurrentMapping] = useState<NutrientRangeMapping>(range.rangeMapping);

  const containerClasses = classNames({
    'flex flex-col justify-center border-2 rounded-lg mx-6 p-6': true,
    'border-primary-700': selected,
  });

  const handleSelect = (): void => {
    if (onSelect) {
      onSelect(id);
    }
  };

  const handleEditTable = (): void => {
    setEditing(true);
  };

  const handleSaveEdit = (): void => {
    if (onSave) {
      onSave(id, { name: rangeName, rangeMapping: currentMapping });
    }
    setEditing(false);
  };

  const handleCancelEdit = (): void => {
    if (onCancel) {
      onCancel();
    }
    setCurrentMapping(range.rangeMapping);
    setEditing(false);
  };

  const handleUpdate = (newMapping: NutrientRangeMapping): void => {
    setCurrentMapping(newMapping);
  };

  return (
    <div className={containerClasses}>
      <div className="flex items-center justify-between  mb-6  w-full">
        <div className="flex gap-2">
          {mode !== 'create' ? (
            <input
              className="h-6 w-6 cursor-pointer"
              checked={selected}
              type="radio"
              onChange={handleSelect}
            />
          ) : null}
          <label className="text-base text-neutral-900 font-medium">{range.name}</label>
        </div>
        {mode === 'edit' && !editing ? (
          <div className="flex items-center">
            <Button
              className="flex items-center"
              variant="text"
              color="primary"
              leftIcon={<Edit />}
              btnClick={handleEditTable}
            >
              EDIT
            </Button>
          </div>
        ) : null}
      </div>
      {editing ? (
        <div className="flex flex-col mb-4">
          <span className="font-normal text-neutral-700 ">Range name</span>
          <div className="flex items-center justify-center border border-neutral-300 rounded	max-w-sm h-10 py-2 px-4">
            <input
              className="w-full outline-0 border-0 bg-transparent"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRangeName(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      <RangeTable
        mode={editing ? 'edit' : 'view'}
        rangeMapping={currentMapping}
        onUpdate={handleUpdate}
      />
      {editing ? (
        <div className="w-full pt-8 flex justify-end gap-3.5 sticky bottom-0 bg-white">
          <Button
            className="h-9 text-xs"
            variant="outlined"
            color="primary"
            btnClick={handleCancelEdit}
          >
            CANCEL
          </Button>
          <Button
            className="h-9 text-xs"
            variant="contained"
            color="primary"
            disabled={rangeName === ''}
            btnClick={handleSaveEdit}
          >
            SAVE RANGE
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default RangeOption;
