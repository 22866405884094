import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ArrowRight } from '../../../../assets/Icon';
import AttributeSorter from '../../../../components/AttributeSorter';
import Button from '../../../../components/Button';
import Hero from '../../../../components/Hero';
import { CUSTOM_HERO_CONSTANTS } from '../../../../constants/heroConstants';
import { selectSelectedNutrients } from '../../../../features/customizeData.slice';
import { selectNutrientData } from '../../../../features/nutrientData.slice';
import BackButton from '../BackButton';

const Attributes = () => {
  const navigate = useNavigate();
  const nutrientData = useSelector(selectNutrientData);
  const selectedNutrients = useSelector(selectSelectedNutrients);

  const handleSave = () => {
    navigate('/snapshot');
  };

  const handleNext = () => {
    navigate('/customize/range');
  };

  return (
    <div className="w-full p-4">
      <BackButton />
      <div className="max-w-[1024px] mx-auto">
        <Hero
          title={CUSTOM_HERO_CONSTANTS.MANAGE_ATTRIBUTES.TITLE}
          text={CUSTOM_HERO_CONSTANTS.MANAGE_ATTRIBUTES.DESCRIPTION}
        />
        <AttributeSorter nutrients={nutrientData} selectedNutrients={selectedNutrients} />
        <div className="flex justify-end gap-11 my-14 mx-4">
          <Button
            className="w-[161px] h-[43px] font-roboto text-sm	font-bold"
            variant="contained"
            color="primary"
            btnClick={handleSave}
          >
            SAVE AND EXIT
          </Button>
          <Button variant="text" color="primary" rightIcon={<ArrowRight />} btnClick={handleNext}>
            NEXT: RANGE
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Attributes;
