import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '../../../assets/Icon';

type Props = {};

const BackButton = (props: Props) => {
  return (
    <>
      <Link to="/">
        <div className="flex items-center">
          <span>
            <ChevronLeft />
          </span>
          <span className="ml-2 text-primary-700 font-roboto">Back to Upload</span>
        </div>
      </Link>
    </>
  );
};

export default BackButton;
