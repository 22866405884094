import classNames from 'classnames';

type Props = {
  children: JSX.Element[];
};

const Stepper = ({ children }: Props) => {
  return (
    <div className="border-b border-neutral-300 gap-x-28 flex items-center justify-center h-20">
      {children}
    </div>
  );
};

export default Stepper;

type StepProps = {
  index: number;
  icon: JSX.Element;
  name: string;
  to: string;
  value: number;
  onClick: (index: number, to: string) => void;
};
export const Step = ({ icon, name, to, index, onClick, value }: StepProps) => {
  const stepClasses = classNames(
    {
      'border-b-2 w-40 h-full border-primary-700 cursor-pointer': index === value,
    },
    {
      'border-b-2 w-40 h-full border-transparent cursor-pointer': index !== value,
    },
  );
  const iconClasses = classNames(
    {
      'h-10 w-10 flex justify-center items-center bg-primary-700 rounded-full mr-2':
        index === value,
    },
    {
      'h-7 w-7 flex justify-center items-center bg-neutral-600 rounded-full mr-2': index !== value,
    },
    'group-hover:h-10 group-hover:w-10 group-hover:flex group-hover:justify-center group-hover:items-center group-hover:bg-primary-700 group-hover:rounded-full group-hover:mr-2',
  );
  const textClasses = classNames(
    {
      'font-semibold text-primary-700 mr-5 font-roboto': index === value,
    },
    'font-roboto group-hover:font-semibold group-hover:text-primary-700 mr-5',
  );

  return (
    <div className="group h-full">
      <div className={stepClasses} onClick={() => onClick(index, to)}>
        <div className="flex h-full justify-center items-center">
          <span className={iconClasses}>{icon}</span>
          <span className={textClasses}>{name}</span>
        </div>
      </div>
    </div>
  );
};
