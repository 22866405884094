import { configureStore } from '@reduxjs/toolkit';
import headerReducer from './features/headerSlice';
import snapshotInfoReducer from './features/snapshotInfoSlice';
import NutrientDataSlice from './features/nutrientData.slice';
import FileDataSlice from './features/fileData.slice';
import CustomizeDataSlice from './features/customizeData.slice';
import RangeDataSlice from './features/rangeData.slice';

export const store = configureStore({
  reducer: {
    headerDataStore: headerReducer,
    snapshotInfoStore: snapshotInfoReducer,
    nutrientDataStore: NutrientDataSlice,
    fileDataStore: FileDataSlice,
    customizeDataStore: CustomizeDataSlice,
    rangeDataStore: RangeDataSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
