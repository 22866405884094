const MIXPANEL_DEV_TOKEN = process.env.REACT_APP_MIXPANEL_DEV_TOKEN ?? '';
const MIXPANEL_PRODUCTION_TOKEN = process.env.REACT_APP_MIXPANEL_PROD_TOKEN ?? '';
export const MIXPANEL_TOKEN =
  process.env.REACT_APP_ENV === 'production' ? MIXPANEL_PRODUCTION_TOKEN : MIXPANEL_DEV_TOKEN;

export const MIXPANEL_PROPS = {
  SNAPSHOTS_COMPLETED: 'Snapshots Completed',
};

export const MIXPANEL_EVENTS = {
  GENERAL: {
    OPENED_THE_WEB_APP: 'Opened the application',
    CLOSED_THE_WEB_APP: 'Closed the application',
  },
  SNAPSHOT_DOWNLOADED: 'Downloaded a snapshot',
  SNAPSHOT_PRINTED: 'Printed a snapshot',
};
