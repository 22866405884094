import { Close } from '../../assets/Icon/index';
import Button, { ButtonProps } from '../Button';

export type ModalAction = {
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
  buttonAlign?: 'start' | 'between' | 'end' | 'center';
  disableBtn?: boolean;
  buttonText?: string;
  footerBtnClick?: (e?: any) => void;
};

type ModalProps = {
  title?: string;
  children: JSX.Element | string;
  onClose: () => void;
  actions?: ModalAction[];
};

const Modal = ({ title, children, onClose, actions }: ModalProps) => {
  return (
    <div
      onClick={() => onClose()}
      className="v-screen h-screen bg-black/[.5] z-20 fixed inset-0 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white text-black z-30 w-[604px] overflow-y-auto max-h-[80%] "
      >
        <div className="sticky top-0 flex justify-between items-center text-xl border-b bg-white z-10">
          <p className="p-4 mb-0 font-medium">{title}</p>
          <div className="p-4 cursor-pointer" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="py-10 px-10 flex h-full flex-col justify-between ">{children}</div>
        <div className="flex bg-white border-t justify-end p-4 gap-4">
          <>
            {actions?.map((action, i) => (
              <Button
                variant={action.variant}
                disabled={action.disableBtn}
                className="h-11"
                color={action.color}
                btnClick={action.footerBtnClick}
                key={i}
              >
                {action.buttonText}
              </Button>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default Modal;
