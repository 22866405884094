import FileInputHeader from '../FileInputHeader';

type DraggerProps = {
  onFileDrop: (files: File[]) => void;
  children?: JSX.Element;
  icon?: JSX.Element;
  titleText?: string;
  className?: string;
};

const DraggerContainer: React.FC<DraggerProps> = ({
  onFileDrop,
  icon,
  titleText,
  children,
  className,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files || [];
    onFileDrop(Array.from(files));
  };

  return (
    <div
      className={`border border-dashed relative border-zinc-650 w-[748px] flex flex-col justify-center items-center ${className}`}
    >
      <FileInputHeader icon={icon} titleText={titleText} />
      <input
        id="fileInput"
        accept=".csv"
        className="opacity-0 absolute top-0 left-0 w-full h-full"
        type="file"
        onChange={handleOnChange}
      />
      {children}
    </div>
  );
};

export default DraggerContainer;
