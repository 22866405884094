import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { NutrientDataMapping, parseNutrientData } from '../utils/nutrient.utils';

type NutrientDataState = {
  nutrients: NutrientDataMapping;
};

const initialState: NutrientDataState = {
  nutrients: {},
};

export const NutrientDataSlice = createSlice({
  name: 'nutrientData',
  initialState,
  reducers: {
    setNutrientData: (state, action: PayloadAction<any>) => {
      state.nutrients = parseNutrientData(action.payload);
    },
    clearNutrientData: (state) => {
      state = initialState;
    },
  },
});

export const selectNutrientData = createSelector(
  (state: RootState) => state.nutrientDataStore.nutrients,
  (nutrientData) => nutrientData,
);

export const { setNutrientData, clearNutrientData } = NutrientDataSlice.actions;

export default NutrientDataSlice.reducer;
