import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_RANGE_MAPPING, RangeData, RangeDataMapping } from '../constants/range.constants';
import { RootState } from '../store';

type RangeDataState = {
  customRanges: RangeDataMapping;
};

const initialState: RangeDataState = {
  customRanges: {},
};

export const RangeDataSlice = createSlice({
  name: 'rangeData',
  initialState,
  reducers: {
    upsertCustomRange: (state, action: PayloadAction<{ id: string; range: RangeData }>) => {
      state.customRanges[action.payload.id] = action.payload.range;
    },
  },
});

export const { upsertCustomRange } = RangeDataSlice.actions;

export const selectCustomRanges = createSelector(
  (state: RootState) => state.rangeDataStore.customRanges,
  (customRanges) => customRanges,
);

export const getCurrentRange = createSelector(
  (state: RootState) => state.customizeDataStore.selectedRange,
  (state: RootState) => state.rangeDataStore.customRanges,
  (selectedRange, customRanges) => {
    return customRanges[selectedRange] ?? DEFAULT_RANGE_MAPPING[selectedRange];
  },
);

export const isCustomRange = createSelector(
  [(state: RootState) => state.rangeDataStore.customRanges, (state, id: string) => id],
  (customRanges, id) => {
    return !!customRanges[id];
  },
);

export default RangeDataSlice.reducer;
