import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { formatLabDate, getInfoMapping, parseLabDate } from '../helpers';
import { RootState } from '../store';

export type SnapshotInfoFormat = 'display' | 'input' | 'output';

export type SnapshotInfo = {
  dateReported: Date | null;
  farmName: string | null;
  fieldName: string | null;
  growerName: string | null;
};

export type DisplaySnapshotInfo = {
  dateReported: string;
  farmName: string;
  fieldName: string;
  growerName: string;
};

export const INITIAL_SNAPSHOT_INFO: SnapshotInfo = {
  dateReported: null,
  farmName: null,
  fieldName: null,
  growerName: null,
};

const snapshotInfoSlice = createSlice({
  name: 'snapshotInfo',
  initialState: INITIAL_SNAPSHOT_INFO,
  reducers: {
    setInputSnapshotInfo: (state, action: PayloadAction<DisplaySnapshotInfo>) => {
      return {
        dateReported: parseLabDate(action.payload.dateReported, 'input'),
        farmName: action.payload.farmName === '' ? null : action.payload.farmName,
        fieldName: action.payload.fieldName === '' ? null : action.payload.fieldName,
        growerName: action.payload.growerName === '' ? null : action.payload.growerName,
      };
    },
    setParsedSnapshotInfo: (state, action: PayloadAction<any[]>) => {
      if (action.payload.length === 0) {
        return INITIAL_SNAPSHOT_INFO;
      }
      const mapping = getInfoMapping(action.payload[0]);
      const dateKey = mapping.get('reportDate');
      const farmKey = mapping.get('farm');
      const fieldKey = mapping.get('field');
      const growerKey = mapping.get('grower');
      return {
        dateReported: !isNil(dateKey)
          ? parseLabDate(action.payload[0][dateKey], 'parsed', dateKey)
          : null,
        farmName: !isNil(farmKey) ? action.payload[0][farmKey] : null,
        fieldName: !isNil(fieldKey) ? action.payload[0][fieldKey] : null,
        growerName: !isNil(growerKey) ? action.payload[0][growerKey] : null,
      };
    },
    updateSnapshotInfo: (state, action: PayloadAction<SnapshotInfo>) => {
      return { ...action.payload };
    },
  },
});

const selectInfo = (state: RootState) => state.snapshotInfoStore;
const selectFormatedSnapshotInfo = (type: SnapshotInfoFormat) => {
  return createSelector(selectInfo, (info) => {
    return {
      dateReported: formatLabDate(info.dateReported, type) ?? '',
      farmName: info.farmName ?? '',
      fieldName: info.fieldName ?? '',
      growerName: info.growerName ?? '',
    } as DisplaySnapshotInfo;
  });
};

export const selectSnapshotInfo = createSelector(selectInfo, (info) => info);

export const selectDisplayableSnapshotInfo = selectFormatedSnapshotInfo('display');

export const selectInputSnapshotInfo = selectFormatedSnapshotInfo('input');

export const selectOutputSnapshotInfo = selectFormatedSnapshotInfo('output');

const { actions, reducer } = snapshotInfoSlice;
export const { setInputSnapshotInfo, setParsedSnapshotInfo, updateSnapshotInfo } = actions;
export default reducer;
