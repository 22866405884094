import { ArrowLeft, File, Upload } from '../../../assets/Icon';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/Hero';
import Papa, { ParseResult } from 'papaparse';
import DraggerContainer from '../../../components/DraggerContainer';
import SelectedFileDisplay from '../../../components/SelectedFileDisplay';
import Button from '../../../components/Button';
import FileInputButton from '../../../components/FileInputButton';
import { useNavigate } from 'react-router-dom';
import { HERO_CONSTANTS } from '../../../constants/heroConstants';
import { setParsedSnapshotInfo } from '../../../features/snapshotInfoSlice';
import { clearNutrientData, setNutrientData } from '../../../features/nutrientData.slice';
import { addFileData, removeFileData, selectFiles } from '../../../features/fileData.slice';

const HomePage = () => {
  const MAX_FILE_SIZE = 100000000;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector(selectFiles);
  const currentFile = files.length > 0 ? files[0] : undefined;

  const onFileDrop = (event: any) => {
    if (event) {
      Papa.parse(event[0], {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult<{ [key: string]: string }>) => {
          dispatch(addFileData(event[0]));
          dispatch(setNutrientData(result.data));
          dispatch(setParsedSnapshotInfo(result?.data || []));
        },
      });
    }
  };

  const handleFileRemove = () => {
    dispatch(clearNutrientData());
    dispatch(removeFileData(0));
  };

  const errorHandler = () => {
    if (!currentFile) {
      return { status: false, message: '' };
    }

    if (currentFile.size > MAX_FILE_SIZE) {
      return { status: true, message: `File exceeds maximum size of ${MAX_FILE_SIZE}.` };
    } else if (!['text/csv', 'application/vnd.ms-excel'].includes(currentFile.type)) {
      return { status: true, message: 'Invalid file format, please upload a CSV file' };
    } else {
      return { status: false, message: '' };
    }
  };

  let { status } = errorHandler();

  return (
    <div className="flex items-center justify-center flex-col pt-12">
      <Hero title={HERO_CONSTANTS.TITLE} text={HERO_CONSTANTS.DESCRIPTION} primary />
      {files.length === 0 ? (
        <DraggerContainer
          onFileDrop={onFileDrop}
          icon={<Upload />}
          titleText="Drag and Drop your CSV file here"
        >
          <FileInputButton
            className="mb-10 mt-6"
            actions={[{ color: 'primary', variant: 'contained', buttonText: 'choose file' }]}
            handleFileInputButton={onFileDrop}
          />
        </DraggerContainer>
      ) : (
        <SelectedFileDisplay
          fileRemove={handleFileRemove}
          fileIcon={<File />}
          file={currentFile}
          binIcon
          validator={errorHandler()}
          handleFileInput={onFileDrop}
        />
      )}
      {files.length !== 0 && (
        <div className="flex justify-between w-[748px] mt-8">
          <Button
            btnClick={handleFileRemove}
            variant="outlined"
            color="primary"
            leftIcon={<ArrowLeft />}
          >
            Back
          </Button>
          <Button
            disabled={status}
            variant="contained"
            color="primary"
            btnClick={() => navigate('/customize/attributes')}
          >
            Create snapshot
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomePage;
