export type InfoKey = 'farm' | 'field' | 'grower' | 'reportDate';

export type InfoMapping = {
  [key in InfoKey]: string[];
};

export const INFO_MAPPING: InfoMapping = {
  farm: ['farm_id', 'Farm'],
  // Identification has a leading space in it because that's how it's formatted in the CSV
  field: ['field_id', 'FarmField', ' Identification'],
  grower: ['grow_name', 'Grower'],
  reportDate: ['rptdate', 'Date'],
};
