import { VerticalLine } from '../../../../assets/Icon';
import RangeInput from '../../../../components/RangeInput';
import { GaugeColors } from '../../../../constants/color.constants';
import { GaugeSection } from '../../../../constants/gauge.constants';
import { SoilNutrient } from '../../../../constants/nutrient.constants';
import { NutrientRange } from '../../../../constants/range.constants';
import { getNutrientLabel } from '../../../../utils/nutrient.utils';

type RangeCustomisationProps = {
  nutrient: SoilNutrient;
  rangeColors: GaugeColors;
  rangeData: NutrientRange;
  onChange: (nutrient: SoilNutrient, newRange: NutrientRange) => void;
};

const RangeCustomisation = ({
  nutrient,
  rangeColors,
  rangeData,
  onChange,
}: RangeCustomisationProps) => {
  const label = getNutrientLabel(nutrient);

  const handleRangeChange = (section: GaugeSection, newValue: number) => {
    const newRange = { ...rangeData };
    switch (section) {
      case 'veryLow':
        newRange.veryLow = newValue;
        break;
      case 'low':
        newRange.low = newValue;
        break;
      case 'optimum':
        newRange.high = newValue;
        break;
      case 'high':
        newRange.veryHigh = newValue;
        break;
      case 'veryHigh':
        newRange.maximum = newValue;
        break;
    }
    onChange(nutrient, newRange);
  };

  return (
    <div className="font-roboto flex flex-col items-center min-h-[444px] w-[391px] border-2 border-neutral-300 rounded p-8">
      <span className="font-bold text-3xl font-meri">{label}</span>
      <div className="rotate-90">
        <VerticalLine height={70} width={4} color={'#CCD4D8'} />
      </div>
      <RangeInput
        color={rangeColors.veryLow}
        currentValue={rangeData.veryLow}
        previousValue={rangeData.minimum}
        section="veryLow"
        onChange={handleRangeChange}
      />
      <RangeInput
        color={rangeColors.low}
        currentValue={rangeData.low}
        previousValue={rangeData.veryLow}
        section="low"
        onChange={handleRangeChange}
      />
      <RangeInput
        color={rangeColors.optimum}
        currentValue={rangeData.high}
        previousValue={rangeData.low}
        section="optimum"
        onChange={handleRangeChange}
      />
      <RangeInput
        color={rangeColors.high}
        currentValue={rangeData.veryHigh}
        previousValue={rangeData.high}
        section="high"
        onChange={handleRangeChange}
      />
      <RangeInput
        color={rangeColors.veryHigh}
        currentValue={rangeData.maximum}
        previousValue={rangeData.veryHigh}
        section="veryHigh"
        onChange={handleRangeChange}
      />
    </div>
  );
};

export default RangeCustomisation;
