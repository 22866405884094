import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Stepper, { Step } from '../../../components/Stepper';
import { StepperConstants } from '../../../constants/stepperConstants';

const Customize = () => {
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleStep = (index: number, to: string) => {
    navigate(to);
    setActiveStep(index);
  };
  useEffect(() => {
    StepperConstants.forEach((el) => {
      if (el.to === location.pathname) {
        setActiveStep(el.index);
      }
    });
  }, [location.pathname]);
  return (
    <div className="m-8 bg-white">
      <Stepper>
        {StepperConstants?.map((el: any, index: number) => (
          <Step
            key={index}
            icon={el.icon}
            name={el.name}
            to={el.to}
            value={activeStep}
            index={index}
            onClick={handleStep}
          />
        ))}
      </Stepper>
      <div className="flex justify-center">
        <Outlet />
      </div>
    </div>
  );
};

export default Customize;
