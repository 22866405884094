import { Menu } from '@headlessui/react';
import { PropsWithChildren } from 'react';

export type NavButtonProps = PropsWithChildren<{
  icon: JSX.Element;
  onClick?: () => void;
}>;

const NavButton: React.FunctionComponent<NavButtonProps> = ({
  children,
  icon,
  onClick,
}: NavButtonProps): JSX.Element => {
  return (
    <Menu.Button
      className="flex flex-col justify-center items-center text-neutral-700 hover:text-primary-500 h-full"
      onClick={onClick}
    >
      {icon}
      <span className="flex items-center text-base text-inherit uppercase font-bold">
        {children}
      </span>
    </Menu.Button>
  );
};

export default NavButton;
