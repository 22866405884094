type FileInputHeaderProps = {
  icon?: JSX.Element;
  titleText?: string;
};

const FileInputHeader: React.FC<FileInputHeaderProps> = ({ icon, titleText }) => {
  return (
    <div className="flex flex-col items-center w-full mt-10">
      <span className="h-[90px] w-12 mb-2 mt-2">{icon}</span>
      <p className="font-normal	text-neutral-600 mb-4 text-2xl	">{titleText}</p>
    </div>
  );
};

export default FileInputHeader;
