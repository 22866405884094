import Gauge from '../../../../components/Gauge';
import { GaugeColors } from '../../../../constants/color.constants';
import { SoilNutrient } from '../../../../constants/nutrient.constants';
import { NutrientRange } from '../../../../constants/range.constants';
import { getGaugeSection } from '../../../../helpers';
import { getNutrientLabel, NutrientData } from '../../../../utils/nutrient.utils';

type GaugeConatinerProps = {
  description: string;
  gaugecolors: GaugeColors;
  nutrient: SoilNutrient;
  nutrientData: NutrientData;
  nutrientRange: NutrientRange;
};

const GaugeConatiner = ({
  description,
  gaugecolors,
  nutrient,
  nutrientData,
  nutrientRange,
}: GaugeConatinerProps) => {
  const label = getNutrientLabel(nutrient);
  return (
    <div className="font-roboto flex flex-col items-center min-h-[444px] w-[391px] border-2 border-neutral-300 rounded p-8">
      <span className="font-bold	text-3xl  font-meri">{label}</span>
      <Gauge
        gaugeColors={gaugecolors}
        nutrientRange={nutrientRange}
        label="Average"
        needleOptions={{
          value: nutrientData.average,
          section: getGaugeSection(nutrientData.average, nutrientRange),
        }}
        rangeOptions={{
          minimum: {
            value: nutrientData.minimum,
            section: getGaugeSection(nutrientData.minimum, nutrientRange),
          },
          maximum: {
            value: nutrientData.maximum,
            section: getGaugeSection(nutrientData.maximum, nutrientRange),
          },
        }}
      />
      <span className="font-normal	text-3xl ">{nutrientData.average.toFixed(1)}</span>
      <div className="border-solid border-t-2 border-t-[#CCD4D8] w-[64px] mt-[32px] mb-[16px]" />
      <p className="text-base	font-normal  text-center	">{description}</p>
    </div>
  );
};

export default GaugeConatiner;
