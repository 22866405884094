import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { RootState, AppDispatch } from '../store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useRouteType = () => {
  const location = useLocation();

  let tag;

  switch (location.pathname) {
    case '/customize/attributes':
    case '/customize/range':
    case '/customize/colors':
      tag = 'custom';
      break;
    case '/snapshot':
      tag = 'snapshot';
      break;
    default:
      tag = 'default';
      break;
  }

  return tag;
};
