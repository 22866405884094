import React from 'react';
import SimpleLayout from '../../components/SimpleLayout';
import { Routes, Route } from 'react-router-dom';
import HomePage from './Home';
import Snapshot from './Snapshot';
import Customize from './Customize';
import Attributes from './Customize/Attributes';
import { useRouteType } from '../../hooks/hooks';
import Range from './Customize/Range';
import ColorSelection from './Customize/ColorSelection';
import FixMidwest from './Tools/FixMidwest';

const AppContainer: React.FC = () => {
  let layoutType = useRouteType();
  return (
    <>
      <SimpleLayout type={layoutType}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/customize" element={<Customize />}>
            <Route path="/customize/attributes" element={<Attributes />} />
            <Route path="/customize/range" element={<Range />} />
            <Route path="/customize/colors" element={<ColorSelection />} />
          </Route>
          <Route path="/snapshot" element={<Snapshot />} />
          <Route path="/tools/clean-up-midwest" element={<FixMidwest />} />
        </Routes>
      </SimpleLayout>
    </>
  );
};

export default AppContainer;
