import classNames from 'classnames';
import React from 'react';
type Props = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  color?: 'primary' | 'danger' | 'warning' | 'default';
  children?: string;
};
const Text: React.FC<Props> = ({ variant = 'p', className, children, color }) => {
  const textStyleClass = classNames(
    {
      'text-6xl font-medium': variant === 'h1',
    },
    {
      'text-5xl font-medium': variant === 'h2',
    },
    {
      'text-4xl font-medium': variant === 'h3',
    },
    {
      'text-3xl font-medium': variant === 'h3',
    },
    {
      'text-2xl font-medium': variant === 'h4',
    },
    {
      'text-xl font-medium': variant === 'h5',
    },
    {
      'text-lg font-medium': variant === 'h6',
    },
    {
      'text-base': variant === 'p',
    },
  );

  const colorStyleClass = classNames(
    {
      'text-primary-500': color === 'primary',
    },
    {
      'text-red-900': color === 'danger',
    },
    {
      'text-yellow-900': color === 'warning',
    },
    {
      'text-neutral-900': color === 'default',
    },
  );

  const customStyleClass = classNames(className);

  switch (variant) {
    case 'h1':
      return (
        <h1 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h1>
      );
    case 'h2':
      return (
        <h2 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h2>
      );
    case 'h3':
      return (
        <h3 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h3>
      );
    case 'h4':
      return (
        <h4 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h4>
      );
    case 'h5':
      return (
        <h5 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h5>
      );
    case 'h6':
      return (
        <h6 className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</h6>
      );
    default:
      return (
        <p className={`${textStyleClass} ${colorStyleClass} ${customStyleClass}`}>{children}</p>
      );
  }
};
export default Text;
