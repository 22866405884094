import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import NavButton, { NavButtonProps } from './NavButton';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';

export type NavDropdownOption = {
  label: string;
  link: string;
};

export type NavDropdownButtonProps = Omit<NavButtonProps, 'onClick'> & {
  label: string;
  options: NavDropdownOption[];
};

const NavDropdownButton: React.FunctionComponent<NavDropdownButtonProps> = ({
  icon,
  label,
  options,
}: NavDropdownButtonProps): JSX.Element => {
  return (
    <div className="relative h-full flex items-center" tabIndex={0}>
      <Menu>
        {({ open }) => (
          <>
            <NavButton icon={icon}>
              {label}
              {open ? (
                <ChevronUpIcon height={20} width={20} />
              ) : (
                <ChevronDownIcon height={20} width={20} />
              )}
            </NavButton>
            <div className="absolute rounded w-[250px] cursor-pointer bg-white z-20 drop-shadow-lg overflow-y-auto translate-x-1/2 right-[50%] top-[100%]">
              <Menu.Items>
                {options.map((option) => {
                  return (
                    <Menu.Item key={option.link}>
                      <Link to={option.link} key={option.link}>
                        <p className="text-sm py-4 px-3 mb-0 capitalize hover:bg-primary-50 text-neutral-500">
                          {option.label}
                        </p>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </div>
          </>
        )}
      </Menu>
    </div>
  );
};

export default NavDropdownButton;
