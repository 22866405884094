import { Attributes, Colors, Range } from '../assets/Icon';

export const StepperConstants = [
  {
    index: 0,
    name: '1. Attributes',
    to: '/customize/attributes',
    icon: <Attributes />,
  },
  {
    index: 1,
    name: '2. Range',
    to: '/customize/range',
    icon: <Range />,
  },
  {
    index: 2,
    name: '3. Colors',
    to: '/customize/colors',
    icon: <Colors />,
  },
];
