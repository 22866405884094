export type GaugeSection = 'veryLow' | 'low' | 'optimum' | 'high' | 'veryHigh';

export type GuageLocation = {
  value: number;
  section: GaugeSection;
};

export const GAUGE_NEEDLE_COLOR = '#444';
export const GAUGE_LABEL_BACKGROUND_COLOR = '#FFF';

export const GAUGE_SECTION_LABELS = {
  veryLow: 'Very Low',
  low: 'Low',
  optimum: 'Optimum',
  high: 'High',
  veryHigh: 'Very High',
};
