import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';

import { SoilNutrient } from '../../constants/nutrient.constants';
import { NutrientRange, NutrientRangeMapping } from '../../constants/range.constants';
import { getNutrientLabel } from '../../utils/nutrient.utils';
import TableInput from '../TableInput';

type RangeItem = {
  key: SoilNutrient;
  title: string;
  data: NutrientRange;
};

type TableMode = 'view' | 'edit';

type RangeTableProps = {
  rangeMapping: NutrientRangeMapping;
  onUpdate: (newMapping: NutrientRangeMapping) => void;
  mode: TableMode;
};

const RangeTable = ({ rangeMapping: nutrientMapping, onUpdate, mode }: RangeTableProps) => {
  const isEditMode = mode === 'edit';

  const tableData: RangeItem[] = useMemo(() => {
    const rangeItems: RangeItem[] = [];
    for (const [key, value] of Object.entries(nutrientMapping)) {
      const nutrient = key as SoilNutrient;
      if (value !== null) {
        rangeItems.push({
          key: nutrient,
          title: getNutrientLabel(nutrient),
          data: value,
        });
      }
    }
    return rangeItems;
  }, [nutrientMapping]);

  const handleInput = (row: RangeItem, type: keyof NutrientRange, value: number) => {
    const newMapping = { ...nutrientMapping };
    const updatedRange = newMapping[row.key];
    if (updatedRange !== null && type !== 'inverse') {
      updatedRange[type] = value;
    }
    newMapping[row.key] = updatedRange;
    onUpdate(newMapping);
  };

  const columns: ColumnsType<RangeItem> = [
    {
      title: 'Name',
      dataIndex: 'title',
    },
    {
      title: 'Very Low',
      dataIndex: 'data',
      render: (value: NutrientRange, record: RangeItem) => {
        return (
          <TableInput
            editable={isEditMode}
            onChange={(newValue) => handleInput(record, 'veryLow', newValue)}
            currentValue={value.veryLow}
            previousValue={value.minimum}
          />
        );
      },
    },
    {
      title: 'Low',
      dataIndex: 'data',
      render: (value: NutrientRange, record: RangeItem) => {
        return (
          <TableInput
            editable={isEditMode}
            onChange={(newValue) => handleInput(record, 'low', newValue)}
            currentValue={value.low}
            previousValue={value.veryLow}
          />
        );
      },
    },
    {
      title: 'Optimum',
      dataIndex: 'data',
      render: (value: NutrientRange, record: RangeItem) => {
        return (
          <TableInput
            editable={isEditMode}
            onChange={(newValue) => handleInput(record, 'high', newValue)}
            currentValue={value.high}
            previousValue={value.low}
          />
        );
      },
    },
    {
      title: 'High',
      dataIndex: 'data',
      render: (value: NutrientRange, record: RangeItem) => {
        return (
          <TableInput
            editable={isEditMode}
            onChange={(newValue) => handleInput(record, 'veryHigh', newValue)}
            currentValue={value.veryHigh}
            previousValue={value.high}
          />
        );
      },
    },
    {
      title: 'Very High',
      dataIndex: 'data',
      render: (value: NutrientRange, record: RangeItem) => {
        return (
          <TableInput
            editable={isEditMode}
            onChange={(newValue) => handleInput(record, 'veryHigh', newValue)}
            currentValue={value.maximum}
            previousValue={value.veryHigh}
          />
        );
      },
    },
  ];

  return (
    <Table
      className="table-antd"
      columns={columns}
      dataSource={tableData}
      scroll={{ y: 300 }}
      bordered
      pagination={false}
    />
  );
};

export default RangeTable;
