import { isNil } from 'lodash';
import { SoilNutrient, UNIVERSIAL_RESULT_MAPPING } from '../constants/nutrient.constants';
import { NutrientRange } from '../constants/range.constants';
import {
  DEFAULT_RECOMMENDATION_MAPPING,
  RecommendationLevel,
  RecommendationLevelMapping,
} from '../constants/recommendation.constants';
import { getGaugeSection } from '../helpers';

export type CsvResult = {
  [key: string]: string;
};

export type NutrientData = {
  data: number[];
  column: string;
  minimum: number;
  maximum: number;
  average: number;
};

export type NutrientDataMapping = {
  [key in SoilNutrient]?: NutrientData;
};

export const getAliasMapping = (): Map<string, SoilNutrient> => {
  const aliasMapping: Map<string, SoilNutrient> = new Map();
  Object.keys(UNIVERSIAL_RESULT_MAPPING).forEach((key) => {
    const nutrient = UNIVERSIAL_RESULT_MAPPING[key as SoilNutrient];
    if (!isNil(nutrient)) {
      nutrient.aliases.forEach((alias) => {
        aliasMapping.set(alias, key as SoilNutrient);
      });
    }
  });
  return aliasMapping;
};

export const parseNutrientData = (csvData: CsvResult[]): NutrientDataMapping => {
  const aliasMap = getAliasMapping();
  const nutrients: NutrientDataMapping = {};
  const nutrientColumns = Object.keys(csvData[0]).filter((key) => aliasMap.has(key.trim()));
  nutrientColumns.forEach((nutrientColumn) => {
    const trimmedColumn = nutrientColumn.trim();
    const nutrientData: NutrientData = {
      data: [],
      column: trimmedColumn,
      minimum: 0,
      maximum: 0,
      average: 0,
    };
    const nutrientKey = aliasMap.get(trimmedColumn);
    csvData.forEach((csvResult) => {
      const nutrientValue = parseFloat(csvResult[nutrientColumn]);
      if (!Number.isNaN(nutrientValue)) {
        nutrientData.data.push(nutrientValue);
      }
    });
    nutrientData.minimum = Math.min(...nutrientData.data);
    nutrientData.maximum = Math.max(...nutrientData.data);
    nutrientData.average = nutrientData.data.reduce((a, b) => a + b, 0) / nutrientData.data.length;
    if (nutrientKey && nutrientData.data.length > 0 && isNil(nutrients[nutrientKey])) {
      nutrients[nutrientKey] = nutrientData;
    }
  });
  return nutrients;
};

export const getNutrientLabel = (nutrient: SoilNutrient) => {
  const nutrientData = UNIVERSIAL_RESULT_MAPPING[nutrient];
  return isNil(nutrientData) ? 'N/A' : nutrientData.label ?? nutrientData.aliases[0];
};

export const getNutrientDescription = (
  nutrient: SoilNutrient,
  data: NutrientData,
  range: NutrientRange,
) => {
  const label = getNutrientLabel(nutrient);
  const minSection = getGaugeSection(data.minimum, range);
  const maxSection = getGaugeSection(data.maximum, range);
  const commonMapping: RecommendationLevelMapping = {
    'veryLow-veryLow': `${label} nutrient levels are at a critical range, focus on a build approach, you will see a high response to a ${label} application.`,
    'veryLow-low': `${label} nutrient levels are at a critical range, focus on a build approach, you will see a high response to a ${label} application.`,
    'veryLow-optimum': `${label} nutrient levels are lower than optimum in some areas, focus on a build and maintenance approach. A variable rate application is recommended. You will see a good response to a ${label} application.`,
    'veryLow-high': `${label} nutrient levels are highly variable, you will see a high response to a ${label} application. Variable rate application is highly recommended.`,
    'veryLow-veryHigh': `${label} nutrient levels are highly variable, you will see a high response to a ${label} application. Variable rate application is highly recommended.`,
    'low-low': `${label} nutrient levels are at a critical range, focus on a build approach, you will see a high response to a ${label} application.`,
    'low-optimum': `${label} nutrient levels are lower than optimum in some areas, focus on a build and maintenance approach. A variable rate application is recommended. You will see a good response to a ${label} application.`,
    'low-high': `${label} nutrient levels are highly variable, you will see a high response to a ${label} application. Variable rate application is highly recommended.`,
    'low-veryHigh': `${label} nutrient levels are highly variable, you will see a high response to a ${label} application. Variable rate application is highly recommended.`,
    'optimum-optimum': `${label} nutrients are good. We recommend a Crop removal application or maintenance application. You will see an average response to a ${label} application.`,
    'optimum-high': `${label} nutrient levels are higher than optimum in some areas, focus on a draw down or maintenance approach. A variable rate application is recommended. You will see a low response to a ${label} application.`,
    'optimum-veryHigh': `${label} nutrient levels are higher than optimum in some areas, focus on a draw down or maintenance approach. A variable rate application is recommended. You will see a low response to a ${label} application.`,
    'high-high': `${label} nutrient levels are higher than optimum in some areas, focus on a draw down or maintenance approach. You will see a very low response to a ${label} application.`,
    'high-veryHigh': `${label} nutrient levels are higher than optimum in some areas, focus on a draw down or maintenance approach. You will see a very low response to a ${label} application.`,
    'veryHigh-veryHigh': `${label} nutrient levels are higher than optimum in some areas, focus on a draw down or maintenance approach. You will see a very low response to a ${label} application.`,
  };
  const defaultMapping = DEFAULT_RECOMMENDATION_MAPPING[nutrient];
  const level = `${minSection}-${maxSection}` as RecommendationLevel;
  return !isNil(defaultMapping) && !isNil(defaultMapping[level])
    ? defaultMapping[level]
    : commonMapping[level];
};
