import { CardIcon, EditButton, VerticalLine } from '../../assets/Icon';
import Button from '../Button';
import {
  HTML2CANVAS_IGNORE_CLASS,
  HTML2CANVAS_LAYOUT_BIG_FIX_CLASS,
  HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS,
} from '../SimpleLayout';

type InfoCardProps = {
  title: string;
  date: string;
  farmName: string;
  name: string;
  handleEdit: () => void;
};

const InfoCard = ({ title, date, farmName, name, handleEdit }: InfoCardProps) => {
  return (
    <div className="px-12 font-roboto	">
      <div className="flex items-center">
        <CardIcon />
        <span
          className={`text-5xl font-normal text-neutral-900 ml-4 ${HTML2CANVAS_LAYOUT_BIG_FIX_CLASS}`}
        >
          {title}
        </span>
      </div>
      <div className="ml-16 my-4">
        <div className="flex items-center font-normal	text-2xl text-neutral-900 mb-3 gap-4">
          {farmName !== '' && (
            <span className={`${HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS}`}>{farmName}</span>
          )}
          {farmName !== '' && (name !== '' || date !== '') ? (
            <VerticalLine color="#677D8B" />
          ) : null}
          {name !== '' && <span className={`${HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS}`}>{name}</span>}
          {date !== '' && (farmName !== '' || name !== '') ? (
            <VerticalLine color="#677D8B" />
          ) : null}
          {date !== '' && <span className={`${HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS}`}>{date}</span>}
        </div>
        <Button
          className={`text-sm font-bold ${HTML2CANVAS_IGNORE_CLASS}`}
          variant="text"
          color="primary"
          leftIcon={<EditButton />}
          btnClick={handleEdit}
        >
          EDIT INFO
        </Button>
      </div>
    </div>
  );
};

export default InfoCard;
