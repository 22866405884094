import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type HeaderSlice = {
  isToggle: boolean;
};

const initialState: HeaderSlice = {
  isToggle: false,
};
export const HeaderDataSlice = createSlice({
  name: 'headerData',
  initialState,
  reducers: {
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.isToggle = action?.payload;
    },
  },
});
export const { setToggle } = HeaderDataSlice.actions;
export default HeaderDataSlice.reducer;
