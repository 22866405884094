import { GaugeSection } from './gauge.constants';

export type GaugeColors = {
  [key in GaugeSection]: string;
};

export type ColorData = {
  name: string;
  colors: GaugeColors;
};

export type ColorDataMapping = {
  [key: string]: ColorData;
};

const DEFAULT_RED = 'rgba(231,51,15, 0.9)';
const DEFAULT_YELLOW = 'rgba(246,215,41, 0.9)';
const DEFAULT_GREEN = 'rgba(71,137,40, 0.9)';

const PASTEL_RED = '#FF4824';
const PASTEL_LIGHT_RED = '#FFC1B5';
const PASTEL_GREEN = '#9FFF8C';
const PASTEL_LIGHT_BLUE = '#B5B3FF';
const PASTEL_BLUE = '#3B41FF';

export const DEFAULT_COLOR_DATA: ColorData = {
  name: 'Default',
  colors: {
    veryLow: DEFAULT_RED,
    low: DEFAULT_YELLOW,
    optimum: DEFAULT_GREEN,
    high: DEFAULT_YELLOW,
    veryHigh: DEFAULT_RED,
  },
};

export const PASTEL_COLOR_DATA: ColorData = {
  name: 'Pastel',
  colors: {
    veryLow: PASTEL_RED,
    low: PASTEL_LIGHT_RED,
    optimum: PASTEL_GREEN,
    high: PASTEL_LIGHT_BLUE,
    veryHigh: PASTEL_BLUE,
  },
};

export const DEFAULT_COLOR_MAPPING: ColorDataMapping = {
  default: DEFAULT_COLOR_DATA,
  pastel: PASTEL_COLOR_DATA,
};
