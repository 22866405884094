import { SoilNutrient } from './nutrient.constants';

export type RecommendationLevel =
  | 'veryLow-veryLow'
  | 'veryLow-low'
  | 'veryLow-optimum'
  | 'veryLow-high'
  | 'veryLow-veryHigh'
  | 'low-low'
  | 'low-optimum'
  | 'low-high'
  | 'low-veryHigh'
  | 'optimum-optimum'
  | 'optimum-high'
  | 'optimum-veryHigh'
  | 'high-high'
  | 'high-veryHigh'
  | 'veryHigh-veryHigh';

export type RecommendationLevelMapping = {
  [key in RecommendationLevel]: string;
};

export type NutrientRecommendationMapping = {
  [key in SoilNutrient]?: RecommendationLevelMapping;
};

const ORGANIC_MATTER_RECOMMENDATIONS = {
  VARIABLE:
    'Organic Matter levels are low, an addition of organic matter would be highly recommended.',
  ADDITION:
    'Organic Matter levels are low in areas. A variable rate application of organic matter would be recommended.',
  OPTIMUM: 'Organic Matter levels are optimum, no action required.',
  HIGH_AND_VARIABLE:
    'Organic Matter levels are high and variable in some areas, no action required.',
  HIGH: 'Organic Matter levels are high, no action required.',
};

const POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS = {
  LOW: 'pH levels are low. An application of Lime is recommended. The type of Lime would depend on the Mg and Ca nutrient levels.',
  VARIABLE:
    'pH levels are variable. An application of Lime is recommended in the low areas. The type of Lime will depend on the Mg and Ca nutrient levels.',
  OPTIMUM: 'pH levels are optimum, no action required.',
  OPTIMUM_AND_HIGH: 'pH levels are optimum and high in some areas. No action required.',
  HIGH: 'pH levels are high, no action required.',
};

const POTASSIUM_PERCENT_RECOMMENDATIONS = {
  VERY_LOW: 'K % is low, please see the K ppm recommendation.',
  LOW: 'K % is low in some areas. Please see the Potassium recommendation.',
  OPTIMUM: 'K % is within optimum range, please see the K ppm recommendation.',
  HIGH: 'K % is high, please see the K ppm recommendation.',
  VERY_HIGH: 'K % is high and variable in some areas, please see the K ppm recommendation.',
};

const MAGNESIUM_PERCENT_RECOMMENDATIONS = {
  VERY_LOW:
    'Mg % is low. Please see Magnesium recommendation. If an application of Lime is being used (pH), it is recommended to use Dolomitic lime.',
  LOW: 'Mg % is low in some areas. Please see Magnesium recommendation. If an application of Lime is being used (pH), it is recommended to use Dolomitic lime.',
  LOW_VARIABLE:
    'Mg % is low in some areas, a variable rate application of Mg fertilizer is required. Please see Mg recommendation. If an application of Lime is being used (pH), it is recommended to use Dolomitic lime as it will address the Mg concerns.',
  OPTIMUM: 'Mg % is within optimum range.',
  HIGH: 'Mg % is high in some areas, please see the Magnesium recommendation. If an application of Lime is being applied (pH), it is recommended to use Calcitic Lime',
  VERY_HIGH:
    'Mg % is high. Please see the Magnesium recommendation. If an application of Lime is being applied (pH), it is recommended to use Calcitic Lime.',
};

const CALCIUM_PERCENT_RECOMMENDATIONS = {
  VERY_LOW:
    'Ca % is low. Please see Calcium recommendation. If a variable rate application of Lime (pH) is being appled, please use Calcitic Lime.',
  LOW: 'Ca % is low in some areas. Please see Calcium recommendation. If a variable rate application of Lime (pH) is being appled, please use Calcitic Lime.',
  OPTIMUM: 'Ca % is within optimum range.',
  HIGH: 'Ca % are variable and high in some areas. Please see Calcium recommendation. If a variable rate application of Lime is being applied (pH), it is recommended to use Dolomitic Lime.',
  VERY_HIGH:
    'Ca % is high. Please see Calcium recommendation. If a variable rate application of Lime is being applied (pH), it is recommended to use Dolomitic Lime.',
};

const BUFFER_RECOMMENDATIONS = {
  LIME_REQUIRED:
    "Buffer pH is the soil's ability to resist change to acidity and determines the amount of Lime to apply. A Lime application is required.",
  PH_AND_LIME:
    "Buffer pH is the soil's ability to resist change to acidity and determines the amount of Lime to apply. Based on pH some Lime may be required.",
  NO_LIME:
    "Buffer pH is the soil's ability to resist change to acidity and determines the amount of Lime to apply. No Lime application is required. ",
};

const CEC_RECOMMENDATIONS = {
  SANDIER_SOIL: 'This field is of sandier soil.',
  LOAM_SOIL: 'This field is more of loam soil.',
  VARIABLE_FIELD: 'The soil in this field is variable.',
  CLAY_SOIL: 'This field is more of a clay soil.',
};

const SODIUM_RECOMMENDATIONS = {
  VERY_LOW:
    'Sodium levels are low. Certain crops like barley, beets, and vegetables may respond to additional sodium.',
  LOW: 'Sodium levels are low in some areas. Certain crops like barley, beets, and vegetables may respond to additional sodium.',
  OPTIMUM: 'Sodium levels are good.',
  HIGH: 'Sodium levels are higher than optimum in some areas. Limit additions of sodium.',
  VERY_HIGH:
    'Sodium levels are high, and could cause tie-up of other cations. Limit additions of sodium. Ensure proper soil drainage.',
  VARIABLE: 'Sodium levels are highly variable. Ensure proper soil drainage in the high areas.',
};

const SODIUM_PERCENTAGE_RECOMMENDATIONS = {
  DEFAULT: 'Sodium base saturation levels are good',
};

const HYDROGRAN_PERCENTAGE_RECOMMENDATION =
  'See pH. H% of zero indicates pH of 7.0 or greater. H% of 10%+ indicates low pH.';

export const DEFAULT_RECOMMENDATION_MAPPING: NutrientRecommendationMapping = {
  organicMatter: {
    'veryLow-veryLow': ORGANIC_MATTER_RECOMMENDATIONS.VARIABLE,
    'veryLow-low': ORGANIC_MATTER_RECOMMENDATIONS.VARIABLE,
    'veryLow-optimum': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'veryLow-high': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'veryLow-veryHigh': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'low-low': ORGANIC_MATTER_RECOMMENDATIONS.VARIABLE,
    'low-optimum': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'low-high': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'low-veryHigh': ORGANIC_MATTER_RECOMMENDATIONS.ADDITION,
    'optimum-optimum': ORGANIC_MATTER_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': ORGANIC_MATTER_RECOMMENDATIONS.HIGH_AND_VARIABLE,
    'optimum-veryHigh': ORGANIC_MATTER_RECOMMENDATIONS.HIGH_AND_VARIABLE,
    'high-high': ORGANIC_MATTER_RECOMMENDATIONS.HIGH,
    'high-veryHigh': ORGANIC_MATTER_RECOMMENDATIONS.HIGH,
    'veryHigh-veryHigh': ORGANIC_MATTER_RECOMMENDATIONS.HIGH,
  },
  potentialOfHydrogen: {
    'veryLow-veryLow': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.LOW,
    'veryLow-low': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.LOW,
    'veryLow-optimum': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'veryLow-high': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'veryLow-veryHigh': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'low-low': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.LOW,
    'low-optimum': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'low-high': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'low-veryHigh': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.VARIABLE,
    'optimum-optimum': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.OPTIMUM_AND_HIGH,
    'optimum-veryHigh': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.OPTIMUM_AND_HIGH,
    'high-high': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.HIGH,
    'high-veryHigh': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.HIGH,
    'veryHigh-veryHigh': POTENTIAL_OF_HYDROGEN_RECOMMENDATIONS.HIGH,
  },
  potassiumPercentage: {
    'veryLow-veryLow': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-low': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-optimum': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'veryLow-high': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'veryLow-veryHigh': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-low': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'low-optimum': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-high': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-veryHigh': POTASSIUM_PERCENT_RECOMMENDATIONS.LOW,
    'optimum-optimum': POTASSIUM_PERCENT_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': POTASSIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'optimum-veryHigh': POTASSIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'high-high': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'high-veryHigh': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'veryHigh-veryHigh': POTASSIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
  },
  magnesiumPercentage: {
    'veryLow-veryLow': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-low': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-optimum': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW,
    'veryLow-high': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW_VARIABLE,
    'veryLow-veryHigh': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW_VARIABLE,
    'low-low': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'low-optimum': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-high': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW_VARIABLE,
    'low-veryHigh': MAGNESIUM_PERCENT_RECOMMENDATIONS.LOW_VARIABLE,
    'optimum-optimum': MAGNESIUM_PERCENT_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': MAGNESIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'optimum-veryHigh': MAGNESIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'high-high': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'high-veryHigh': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'veryHigh-veryHigh': MAGNESIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
  },
  calciumPercentage: {
    'veryLow-veryLow': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-low': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'veryLow-optimum': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'veryLow-high': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'veryLow-veryHigh': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-low': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_LOW,
    'low-optimum': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-high': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'low-veryHigh': CALCIUM_PERCENT_RECOMMENDATIONS.LOW,
    'optimum-optimum': CALCIUM_PERCENT_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': CALCIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'optimum-veryHigh': CALCIUM_PERCENT_RECOMMENDATIONS.HIGH,
    'high-high': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'high-veryHigh': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
    'veryHigh-veryHigh': CALCIUM_PERCENT_RECOMMENDATIONS.VERY_HIGH,
  },
  bufferPotentialOfHydrogen: {
    'veryLow-veryLow': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'veryLow-low': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'veryLow-optimum': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'veryLow-high': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'veryLow-veryHigh': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'low-low': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'low-optimum': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'low-high': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'low-veryHigh': BUFFER_RECOMMENDATIONS.LIME_REQUIRED,
    'optimum-optimum': BUFFER_RECOMMENDATIONS.PH_AND_LIME,
    'optimum-high': BUFFER_RECOMMENDATIONS.PH_AND_LIME,
    'optimum-veryHigh': BUFFER_RECOMMENDATIONS.PH_AND_LIME,
    'high-high': BUFFER_RECOMMENDATIONS.NO_LIME,
    'high-veryHigh': BUFFER_RECOMMENDATIONS.NO_LIME,
    'veryHigh-veryHigh': BUFFER_RECOMMENDATIONS.NO_LIME,
  },
  cationExchangeCapacity: {
    'veryLow-veryLow': CEC_RECOMMENDATIONS.SANDIER_SOIL,
    'veryLow-low': CEC_RECOMMENDATIONS.SANDIER_SOIL,
    'veryLow-optimum': CEC_RECOMMENDATIONS.LOAM_SOIL,
    'veryLow-high': CEC_RECOMMENDATIONS.VARIABLE_FIELD,
    'veryLow-veryHigh': CEC_RECOMMENDATIONS.VARIABLE_FIELD,
    'low-low': CEC_RECOMMENDATIONS.SANDIER_SOIL,
    'low-optimum': CEC_RECOMMENDATIONS.LOAM_SOIL,
    'low-high': CEC_RECOMMENDATIONS.VARIABLE_FIELD,
    'low-veryHigh': CEC_RECOMMENDATIONS.VARIABLE_FIELD,
    'optimum-optimum': CEC_RECOMMENDATIONS.LOAM_SOIL,
    'optimum-high': CEC_RECOMMENDATIONS.LOAM_SOIL,
    'optimum-veryHigh': CEC_RECOMMENDATIONS.LOAM_SOIL,
    'high-high': CEC_RECOMMENDATIONS.CLAY_SOIL,
    'high-veryHigh': CEC_RECOMMENDATIONS.CLAY_SOIL,
    'veryHigh-veryHigh': CEC_RECOMMENDATIONS.CLAY_SOIL,
  },
  sodium: {
    'veryLow-veryLow': SODIUM_RECOMMENDATIONS.VERY_LOW,
    'veryLow-low': SODIUM_RECOMMENDATIONS.VERY_LOW,
    'veryLow-optimum': SODIUM_RECOMMENDATIONS.LOW,
    'veryLow-high': SODIUM_RECOMMENDATIONS.VARIABLE,
    'veryLow-veryHigh': SODIUM_RECOMMENDATIONS.VARIABLE,
    'low-low': SODIUM_RECOMMENDATIONS.LOW,
    'low-optimum': SODIUM_RECOMMENDATIONS.LOW,
    'low-high': SODIUM_RECOMMENDATIONS.VARIABLE,
    'low-veryHigh': SODIUM_RECOMMENDATIONS.VARIABLE,
    'optimum-optimum': SODIUM_RECOMMENDATIONS.OPTIMUM,
    'optimum-high': SODIUM_RECOMMENDATIONS.HIGH,
    'optimum-veryHigh': SODIUM_RECOMMENDATIONS.VARIABLE,
    'high-high': SODIUM_RECOMMENDATIONS.HIGH,
    'high-veryHigh': SODIUM_RECOMMENDATIONS.VERY_HIGH,
    'veryHigh-veryHigh': SODIUM_RECOMMENDATIONS.VERY_HIGH,
  },
  sodiumPercentage: {
    'veryLow-veryLow': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'veryLow-low': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'veryLow-optimum': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'veryLow-high': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'veryLow-veryHigh': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'low-low': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'low-optimum': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'low-high': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'low-veryHigh': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'optimum-optimum': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'optimum-high': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'optimum-veryHigh': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'high-high': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'high-veryHigh': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
    'veryHigh-veryHigh': SODIUM_PERCENTAGE_RECOMMENDATIONS.DEFAULT,
  },
  hydrogenPercentage: {
    'veryLow-veryLow': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'veryLow-low': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'veryLow-optimum': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'veryLow-high': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'veryLow-veryHigh': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'low-low': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'low-optimum': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'low-high': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'low-veryHigh': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'optimum-optimum': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'optimum-high': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'optimum-veryHigh': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'high-high': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'high-veryHigh': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
    'veryHigh-veryHigh': HYDROGRAN_PERCENTAGE_RECOMMENDATION,
  },
};
