import React, { useState } from 'react';

const NUMBER_PATTERN = /^[0-9]*\.?[0-9]*$/;

type TableInputProps = {
  editable: boolean;
  onChange: (value: number) => void;
  currentValue: number | undefined;
  previousValue: number | undefined;
  className?: string;
};

const TableInput = ({
  editable,
  onChange,
  currentValue,
  previousValue,
  className,
}: TableInputProps) => {
  const [inputValue, setInputValue] = useState<string | null>(
    currentValue ? currentValue.toString() : null,
  );
  const [editing, setEditing] = useState<boolean>(false);

  const validateTableInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NUMBER_PATTERN.test(e.target.value)) {
      setInputValue(e.target.value);
    } else if (e.target.value === '') {
      setInputValue(null);
    }
  };

  const updateTableInput = (): void => {
    if (inputValue && NUMBER_PATTERN.test(inputValue)) {
      const newValue = parseFloat(inputValue);
      onChange(newValue);
      setEditing(false);
      setInputValue(newValue.toString());
    }
  };

  const showInput = (): void => {
    setEditing(true);
  };

  return (
    <>
      {editable && editing ? (
        <div className="flex items-center justify-center border border-[transparent] focus-within:border-primary-500 rounded max-w-sm py-1">
          <span className="pl-2 whitespace-pre">{previousValue} - </span>
          <input
            className="w-full outline-0 border-0 bg-transparent focus:outline-none"
            onChange={validateTableInput}
            value={inputValue ? inputValue : ''}
            onBlur={updateTableInput}
            autoFocus
          />
        </div>
      ) : (
        <div className="border border-[transparent] py-1">
          <span className="pl-2 whitespace-pre">{previousValue} - </span>
          <span onClick={editable ? showInput : undefined}>{currentValue}</span>
        </div>
      )}
    </>
  );
};

export default TableInput;
