import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type FileDataState = {
  files: File[];
};

const initialState: FileDataState = {
  files: [],
};

export const FileDataSlice = createSlice({
  name: 'fileData',
  initialState,
  reducers: {
    addFileData: (state, action: PayloadAction<File>) => {
      state.files.push(action.payload);
    },
    clearFileData: (state) => {
      state.files = [];
    },
    removeFileData: (state, action: PayloadAction<number>) => {
      state.files.splice(action.payload, 1);
    },
  },
});

export const { addFileData, clearFileData, removeFileData } = FileDataSlice.actions;

export const selectFiles = createSelector(
  (state: RootState) => state.fileDataStore.files,
  (files) => files,
);

export default FileDataSlice.reducer;
