import MixpanelBrowser, { Mixpanel } from 'mixpanel-browser';
import { isNil } from 'lodash';

import { CURRENT_ENV, Environment } from '../constants/common.constants';
import { MIXPANEL_TOKEN } from '../constants/analytics.constants';

const skipAnalytics = (userId: string, env: Environment) => {
  return false;
};

class Analytics {
  private static _instance: Analytics;
  private mixpanelApi: Mixpanel;
  private _skipAnalytics: boolean = false;

  private constructor() {
    this.mixpanelApi = MixpanelBrowser;
    if (isNil(MIXPANEL_TOKEN) || MIXPANEL_TOKEN === '') {
      console.warn('Mixpanel token is not set.');
    }
    this.mixpanelApi.init(MIXPANEL_TOKEN, {}, '');
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async trackEvent(EventName: string, data?: object) {
    return new Promise((resolve, reject) => {
      try {
        if (this._skipAnalytics) {
          return resolve(true);
        }
        this.mixpanelApi.track(EventName, data, () => {
          resolve(true);
        });
      } catch (error) {
        reject(false);
      }
    });
  }

  async trackAnonymousEvent(eventName: string, data?: object) {
    if (this._skipAnalytics) {
      return;
    }

    try {
      const properties = isNil(data)
        ? { token: MIXPANEL_TOKEN }
        : { token: MIXPANEL_TOKEN, ...data };
      const reqData = {
        event: eventName,
        properties: properties,
      };
      const body = new URLSearchParams();
      body.append('data', JSON.stringify(reqData));
      const options = {
        method: 'POST',
        body: body,
      };
      await fetch('https://api.mixpanel.com/track', options);
    } catch (err) {
      console.error('Error tracking anonymous mixpanel event', err);
    }
  }

  setAlias(userId: string) {
    try {
      this.mixpanelApi.alias(userId);
    } catch (err) {
      console.error('Error trying to set mix panel alias', err);
    }
  }

  setUserData(userId: string) {
    if (skipAnalytics(userId, CURRENT_ENV)) {
      console.warn('Skipping analytics.');
      this._skipAnalytics = true;
      return;
    }
    this.mixpanelApi.identify(userId);
  }

  increment(prop: string, amount: number = 1) {
    try {
      this.mixpanelApi.people.increment(prop, amount);
    } catch (err) {
      console.error('Error trying to increment mix panel', err);
    }
  }

  setPersonData(userId: string, email: string, displayName: string) {
    try {
      this.mixpanelApi.people.set({
        $userId: userId,
        $email: email,
        name: displayName,
      });
    } catch (err) {
      console.error('Error trying to set mix panel person data', err);
    }
  }

  reset() {
    this.mixpanelApi.reset();
  }
}

export default Analytics.Instance;
