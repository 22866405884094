import { useState } from 'react';
import { ChevronDown, ChevronUp } from '../../assets/Icon';

export type DropdownOption = {
  value: string;
  label: string;
};

type DropdownProps = {
  options: DropdownOption[];
  title: string;
  value: string;
  onChange: (newValue: string) => void;
};

const Dropdown = ({ options, title, value, onChange }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = options.find((el) => el.value === value);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: DropdownOption) => {
    setIsOpen(false);
    onChange(option.value);
  };

  return (
    <span className="relative">
      <button
        className="flex outline-none px-4 py-6 justify-between items-center w-[300px] h-[74px] border border-x-neutral-400 border-y-0"
        onClick={toggleDropdown}
      >
        <span className="flex flex-col justify-center items-start">
          <span className="text-primary-700 text-sm font-normal">{title}</span>
          <span className="text-neutral-900 text-base capitalize">{selectedOption?.label}</span>
        </span>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {isOpen && (
        <div className="absolute rounded w-[300px] h-[236px] pt-3 cursor-pointer bg-white	z-20 drop-shadow-lg overflow-y-auto">
          {options.map((option) => {
            return (
              <div
                key={option.value}
                className="py-3 px-4 capitalize hover:bg-primary-50"
                onClick={() => handleSelectOption(option)}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </span>
  );
};

export default Dropdown;
