export const CUSTOM_HERO_CONSTANTS = {
  MANAGE_ATTRIBUTES: {
    TITLE: 'Manage Attributes',
    DESCRIPTION:
      'The attributes included are shown on the snapshot by default. Additional attributes tested will be hidden. You can manage which attributes to include in your soil snapshot.',
  },
  RANGE: {
    TITLE: 'Range',
    DESCRIPTION:
      'Please select your preferred range for the attributes. You can continue with the default range or create your own custom range. ',
  },
  COLOR: {
    TITLE: 'Colors',
    DESCRIPTION:
      'You may choose from the following color templates to set your threshold colors for the gauge component. ',
  },
};
export const HERO_CONSTANTS = {
  TITLE: 'Create New Snapshot',
  DESCRIPTION: 'Upload your soil test results',
};
