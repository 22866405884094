import { useMemo, useState } from 'react';
import { Transfer } from 'antd';
import Text from '../Text';
import { NutrientDataMapping } from '../../utils/nutrient.utils';
import { SoilNutrient } from '../../constants/nutrient.constants';
import { TransferDirection, TransferItem } from 'antd/lib/transfer';
import {
  addSelectedAttributes,
  removeSelectedAttributes,
} from '../../features/customizeData.slice';
import { useDispatch } from 'react-redux';

// Hack to hide the icons on the Transfer. Didn't see an option for this. We will fix this when we make our own
// component.
const HIDE_BUTTON_ICON_STYLES = `.ant-btn-primary > .anticon-right, .ant-btn-primary > .anticon-left {
    display: none;
  }`;

type AttributesSorterProps = {
  nutrients: NutrientDataMapping;
  selectedNutrients: SoilNutrient[];
};

const AttributeSorter = ({ nutrients, selectedNutrients }: AttributesSorterProps) => {
  const dispatch = useDispatch();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const data = useMemo(() => {
    const transferItems: TransferItem[] = [];
    for (const [key, value] of Object.entries(nutrients)) {
      transferItems.push({
        key,
        title: value.column,
        description: `(Average: ${value.average.toFixed(1)})`,
      });
    }
    return transferItems;
  }, [nutrients]);

  const handleChange = (
    selectedKeys: string[],
    direction: TransferDirection,
    movingKeys: string[],
  ) => {
    if (direction === 'right') {
      dispatch(addSelectedAttributes(movingKeys as SoilNutrient[]));
    } else {
      dispatch(removeSelectedAttributes(movingKeys as SoilNutrient[]));
    }
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <div className="antd-global flex-col items-center justify-center">
      <style>{HIDE_BUTTON_ICON_STYLES}</style>
      <div className="flex flex-1 justify-between">
        <Text className="max-w-[415px] flex-1 mb-6">Additional Attributes:</Text>
        <Text className="max-w-[415px] flex-1 mb-6">Included Attributes:</Text>
      </div>
      <Transfer
        dataSource={data}
        targetKeys={selectedNutrients}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        operationStyle={{ margin: '0 32px' }}
        selectAllLabels={[
          ({ selectedCount, totalCount }) => (
            <span>
              {selectedCount === 0
                ? `${totalCount} attributes`
                : `${selectedCount} attributes selected`}
            </span>
          ),
          ({ selectedCount, totalCount }) => (
            <span>
              <span>
                {selectedCount === 0
                  ? `${totalCount} attributes`
                  : `${selectedCount} attributes selected`}
              </span>
            </span>
          ),
        ]}
        operations={['ADD   >', '< REMOVE']}
        render={(item) => (
          <>
            <span className="font-roboto font-bold text-base text-neutral-900">{item.title}</span>
            <span className="font-roboto font-normal text-base text-neutral-900 ml-2">
              {item.description}
            </span>
          </>
        )}
        style={{ marginBottom: 16 }}
      />
    </div>
  );
};

export default AttributeSorter;
