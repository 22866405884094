import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { DeveronLogo, SoilSnapshot } from '../../assets/Icon';
import { DEFAULT_RANGE_MAPPING } from '../../constants/range.constants';
import { selectCustomRanges } from '../../features/rangeData.slice';
import { selectOutputSnapshotInfo } from '../../features/snapshotInfoSlice';
import { formatFileName } from '../../helpers';
import Analytics from '../../utils/analytics.utils';
import Footer from '../Footer';
import Header from '../Header';
import { MIXPANEL_EVENTS } from '../../constants/analytics.constants';

export const HTML2CANVAS_IGNORE_CLASS = 'html2canvas-ignore';
export const HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS = 'html2canvas-layout-small-fix';
export const HTML2CANVAS_LAYOUT_BIG_FIX_CLASS = 'html2canvas-layout-big-fix';

interface SimpleLayoutProps {
  children: JSX.Element;
  type: string;
}

const ignoreElementsHandler = (element: Element): boolean => {
  return element.classList.contains(HTML2CANVAS_IGNORE_CLASS);
};

const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children, type }) => {
  const customRanges = useSelector(selectCustomRanges);
  const snapshotInfo = useSelector(selectOutputSnapshotInfo);
  const content = useRef(null);

  const handleDownloadAndPrint = async (type: string) => {
    // html2canvas doesn't like to work with hidden elements so we need to show it first
    const container = document.getElementById('snapshot-document-container');
    container?.classList.remove('hidden');

    // html2canvas also seems to mess up the alignment of our icons
    const smallLayoutFixes = Array.from(
      document.getElementsByClassName(HTML2CANVAS_LAYOUT_SMALL_FIX_CLASS),
    );
    smallLayoutFixes.forEach((element) => element.classList.add('mb-6'));

    const bigLayoutFixes = Array.from(
      document.getElementsByClassName(HTML2CANVAS_LAYOUT_BIG_FIX_CLASS),
    );
    bigLayoutFixes.forEach((element) => element.classList.add('mb-8'));

    const documentPages: HTMLElement[] = [];
    let currentPage = 1;
    let currentElement = document.getElementById(`snapshot-document-page-${currentPage}`);
    while (currentElement) {
      documentPages.push(currentElement);
      currentPage++;
      currentElement = document.getElementById(`snapshot-document-page-${currentPage}`);
    }

    const doc = new jsPDF('p', 'mm', 'a4');
    let index = 0;
    for (const page of documentPages) {
      if (index > 0) {
        doc.addPage();
      }
      doc.setPage(index + 1);
      const canvas = await html2canvas(page, {
        logging: true,
        useCORS: true,
        ignoreElements: ignoreElementsHandler,
      });
      const imgData = canvas.toDataURL('img/png');
      let imgWidth = 190;
      let imgHeight = (canvas.height * imgWidth) / canvas.width + 5;
      doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, '', 'FAST');
      index++;
    }

    const fileName = formatFileName(
      snapshotInfo.growerName,
      snapshotInfo.farmName,
      snapshotInfo.dateReported,
    );

    if (type === 'download') {
      doc.save(fileName);
      Analytics.trackEvent(MIXPANEL_EVENTS.SNAPSHOT_DOWNLOADED, { fileName });
    } else if (type === 'print') {
      doc.autoPrint();
      window.open(doc.output('bloburl'));
      Analytics.trackEvent(MIXPANEL_EVENTS.SNAPSHOT_PRINTED, { fileName });
    }

    // Reset the document from the html2canvas specific styling
    container?.classList.add('hidden');
    smallLayoutFixes.forEach((element) => element.classList.remove('mb-6'));
    bigLayoutFixes.forEach((element) => element.classList.remove('mb-8'));
  };

  return (
    <>
      {type === 'default' && (
        <div className="flex flex-col h-screen">
          <Header logo={<DeveronLogo />} icon={<SoilSnapshot />} />
          <main className="overflow-y-auto body-content-height grow scrollbar-hide">
            {children}
          </main>
          <Footer />
        </div>
      )}
      {type === 'custom' && (
        <div className="flex flex-col min-h-screen">
          <Header icon={<SoilSnapshot />} />
          <main className="overflow-y-auto grow bg-[#E2E7E9]">{children}</main>
          <Footer className="bg-[#E2E7E9]" />
        </div>
      )}
      {type === 'snapshot' && (
        <div className="flex flex-col min-h-screen">
          <Header
            icon={<SoilSnapshot />}
            onDownloadclick={() => handleDownloadAndPrint('download')}
            onPrintClick={() => handleDownloadAndPrint('print')}
            dropdownOptions={{
              ...DEFAULT_RANGE_MAPPING,
              ...customRanges,
            }}
          />
          <main ref={content} className="overflow-y-auto grow bg-[#E2E7E9] print:bg-white">
            {children}
          </main>
          <Footer className="bg-[#E2E7E9]" />
        </div>
      )}
    </>
  );
};

export default SimpleLayout;
