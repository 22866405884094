import { GaugeSection, GAUGE_SECTION_LABELS } from '../../constants/gauge.constants';
import TableInput from '../TableInput';

type RangeInputProps = {
  color: string;
  currentValue: number;
  previousValue: number;
  section: GaugeSection;
  onChange: (section: GaugeSection, newValue: number) => void;
};

const RangeInput = ({ color, currentValue, previousValue, section, onChange }: RangeInputProps) => {
  const handleInputChange = (value: number) => {
    onChange(section, value);
  };

  return (
    <div className="flex gap-[10px] items-center justify-between w-full mb-5">
      <div className="flex items-center">
        <div style={{ background: color }} className={`w-[16px] h-[16px] rounded-full`} />
        <span className="flex font-roboto text-base font-normal justify-items-start ml-3.5">
          {GAUGE_SECTION_LABELS[section]}
        </span>
      </div>
      <TableInput
        className="w-[136px] border-[1px] border-neutral-300 rounded px-4"
        editable
        currentValue={currentValue}
        previousValue={previousValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default RangeInput;
