import classNames from 'classnames';
import Text from '../Text';

type HeroProps = {
  title: string;
  text: string;
  primary?: boolean;
};

const Hero: React.FC<HeroProps> = ({ title, text, primary }) => {
  const heroContainerClass = classNames('flex flex-col items-center  mb-16', {
    'mt-2.5': !primary,
  });
  const subtitleClass = classNames({
    'font-roboto max-w-[642px]	text-neutral-700 text-center': true,
    'mt-4': !primary,
    'mt-2': primary,
  });
  return (
    <section className={heroContainerClass}>
      <Text
        variant={primary ? 'h3' : 'h4'}
        className="font-roboto mb-0"
        color={primary ? 'primary' : 'default'}
      >
        {title}
      </Text>
      <Text className={subtitleClass}>{text}</Text>
    </section>
  );
};

export default Hero;
