import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pull, union } from 'lodash';
import { DEFAULT_COLOR_MAPPING } from '../constants/color.constants';
import { DEFAULT_NUTRIENTS, SoilNutrient } from '../constants/nutrient.constants';
import { RootState } from '../store';

type CustomizeDataState = {
  selectedAttributes: SoilNutrient[];
  selectedRange: string;
  selectedColor: string;
};

const initialState: CustomizeDataState = {
  selectedAttributes: DEFAULT_NUTRIENTS,
  selectedRange: 'defaultOntario',
  selectedColor: 'default',
};

export const CustomizeDataSlice = createSlice({
  name: 'customizeData',
  initialState,
  reducers: {
    addSelectedAttributes: (state, action: PayloadAction<SoilNutrient[]>) => {
      state.selectedAttributes = union(state.selectedAttributes, action.payload);
    },
    removeSelectedAttributes: (state, action: PayloadAction<SoilNutrient[]>) => {
      pull(state.selectedAttributes, ...action.payload);
    },
    setSelectedRange: (state, action: PayloadAction<string>) => {
      state.selectedRange = action.payload;
    },
    setSelectedColor: (state, action: PayloadAction<string>) => {
      state.selectedColor = action.payload;
    },
  },
});

export const {
  addSelectedAttributes,
  removeSelectedAttributes,
  setSelectedRange,
  setSelectedColor,
} = CustomizeDataSlice.actions;

export const selectSelectedNutrients = createSelector(
  (state: RootState) => state.customizeDataStore.selectedAttributes,
  (nutrientData) => nutrientData,
);

export const selectSelectedRange = createSelector(
  (state: RootState) => state.customizeDataStore.selectedRange,
  (selectedRange) => selectedRange,
);

export const selectSelectedColor = createSelector(
  (state: RootState) => state.customizeDataStore.selectedColor,
  (selectedColor) => selectedColor,
);

export const selectCurrentColor = createSelector(
  (state: RootState) => state.customizeDataStore.selectedColor,
  (selectedColor) => DEFAULT_COLOR_MAPPING[selectedColor],
);

export default CustomizeDataSlice.reducer;
