import { Chart, ArcElement } from 'chart.js';
import { clamp, isNil } from 'lodash';
import { Doughnut } from 'react-chartjs-2';

import { GaugeColors } from '../../constants/color.constants';
import {
  GAUGE_LABEL_BACKGROUND_COLOR,
  GAUGE_NEEDLE_COLOR,
  GuageLocation,
} from '../../constants/gauge.constants';
import { NutrientRange } from '../../constants/range.constants';
import { getGaugeAngle } from '../../helpers';

Chart.register(ArcElement);

type GaugeProps = {
  gaugeColors: GaugeColors;
  nutrientRange: NutrientRange;
  label?: string;
  needleOptions?: GuageLocation;
  rangeOptions?: {
    minimum: GuageLocation;
    maximum: GuageLocation;
  };
};

const Gauge = ({ gaugeColors, nutrientRange, label, needleOptions, rangeOptions }: GaugeProps) => {
  const calculateRangeData = () => {
    if (isNil(rangeOptions)) {
      return { datasets: [] };
    }

    const greyAreaStart = getGaugeAngle(
      rangeOptions.minimum.value,
      rangeOptions.minimum.section,
      nutrientRange,
    );
    const greyAreaEnd = getGaugeAngle(
      rangeOptions.maximum.value,
      rangeOptions.maximum.section,
      nutrientRange,
    );
    const greyAreaTotal = Math.PI;
    return {
      datasets: [
        {
          data: [greyAreaStart, greyAreaEnd - greyAreaStart, greyAreaTotal - greyAreaEnd],
          backgroundColor: ['transparent', 'rgb(0,0,0,0.3)', 'transparent'],
          borderWidth: 0,
          cutout: '0%',
          circumference: 180,
          rotation: 270,
        },
      ],
    };
  };
  const guageNeedle = {
    id: 'guageNeedle',
    afterDraw(Chart: any) {
      const {
        ctx,
        chartArea: { width, height },
      } = Chart;
      // Add PI because the gauge area for the needle seems to be 180-360 rather than 0-180
      const needleAngle = !isNil(needleOptions)
        ? clamp(
            getGaugeAngle(needleOptions.value, needleOptions.section, nutrientRange),
            0,
            Math.PI,
          ) + Math.PI
        : 0;

      const cx = width / 2;
      const cy = Chart._metasets[0].data[0].y;
      const needleLength = height - 10;

      // Needle draw
      ctx.save();
      ctx.translate(cx, cy);
      ctx.rotate(needleAngle);
      ctx.beginPath();
      ctx.moveTo(0, -2);
      ctx.lineTo(needleLength, 0);
      ctx.lineTo(0, 2);
      ctx.fillStyle = GAUGE_NEEDLE_COLOR;
      ctx.fill();
      ctx.restore();

      // White background for label
      ctx.save();
      ctx.translate(0, 0);
      ctx.beginPath();
      ctx.arc(cx, cy, 25, 3, 10);
      ctx.fillStyle = GAUGE_LABEL_BACKGROUND_COLOR;
      ctx.fill();
      ctx.closePath();
      ctx.restore();

      // Label
      ctx.save();
      ctx.font = '16px Roboto';
      ctx.textAlign = 'center';
      ctx.fillText(label, cx, cy);
      ctx.restore();
    },
  };

  const dialData = {
    datasets: [
      {
        data: [20, 20, 20, 20, 20],
        backgroundColor: nutrientRange.inverse
          ? [
              gaugeColors.veryHigh,
              gaugeColors.high,
              gaugeColors.optimum,
              gaugeColors.low,
              gaugeColors.veryLow,
            ]
          : [
              gaugeColors.veryLow,
              gaugeColors.low,
              gaugeColors.optimum,
              gaugeColors.high,
              gaugeColors.veryHigh,
            ],
        borderWidth: 0,
        cutout: '50%',
        circumference: 180,
        rotation: 270,
        //hoverOffset: 20,
      },
    ],
  };

  const rangeData = calculateRangeData();

  return (
    <>
      <div className="h-[130px] w-[240px] m-4 relative">
        <Doughnut data={dialData} options={{ maintainAspectRatio: false }} />
        {(needleOptions || rangeOptions) && (
          <div className="h-[130px] w-[240px] top-0 right-0 absolute">
            <Doughnut
              data={rangeData}
              plugins={needleOptions ? [guageNeedle] : []}
              options={{ maintainAspectRatio: false }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Gauge;
