import React, { MutableRefObject, useRef } from 'react';
import Button, { ButtonProps } from '../Button';

type FileInputButtonAction = Pick<ButtonProps, 'color' | 'variant' | 'disabled'> & {
  buttonText?: string;
};

type FileInputButtonProps = {
  className?: string;
  handleFileInputButton?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  actions?: FileInputButtonAction[];
};

const FileInputButton: React.FC<FileInputButtonProps> = ({
  handleFileInputButton,
  className,
  actions,
}) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className={className}>
      {actions?.map((action, i) => (
        <Button
          disabled={action.disabled}
          variant={action.variant}
          color={action.color}
          btnClick={handleClick}
          key={i}
        >
          {action.buttonText}
        </Button>
      ))}

      <input
        type="file"
        accept=".csv"
        ref={inputRef}
        onChange={handleFileInputButton}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default FileInputButton;
