import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

import NavDropdownButton from './NavDropdownButton';

export type NavBarProps = {};

const NavBar: React.FunctionComponent<NavBarProps> = (props: NavBarProps): JSX.Element => {
  return (
    <nav className="h-full flex items-center pr-5">
      <NavDropdownButton
        icon={<WrenchScrewdriverIcon height={30} width={30} />}
        label="Tools"
        options={[{ label: 'Clean up Midwest Labs CSV', link: '/tools/clean-up-midwest' }]}
      />
    </nav>
  );
};

export default NavBar;
