import { useEffect, useState } from 'react';
import InputField from '../../../components/InputField';
import InfoCard from '../../../components/InfoCard';
import Modal, { ModalAction } from '../../../components/Modal';
import { Powered, SoilSnapshot } from '../../../assets/Icon';
import GaugeContainer from './GaugeContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import RangeCustomisation from './RangeCustomisation';
import { useDispatch } from 'react-redux';

import { chunk, isNil, uniqueId } from 'lodash';
import {
  DisplaySnapshotInfo,
  selectDisplayableSnapshotInfo,
  selectInputSnapshotInfo,
  updateSnapshotInfo,
} from '../../../features/snapshotInfoSlice';
import { parseLabDate } from '../../../helpers';
import { selectNutrientData } from '../../../features/nutrientData.slice';
import {
  selectCurrentColor,
  selectSelectedNutrients,
  selectSelectedRange,
  setSelectedRange,
} from '../../../features/customizeData.slice';
import {
  getCurrentRange,
  isCustomRange,
  upsertCustomRange,
} from '../../../features/rangeData.slice';
import { getNutrientDescription } from '../../../utils/nutrient.utils';
import { SoilNutrient } from '../../../constants/nutrient.constants';
import { NutrientRange } from '../../../constants/range.constants';

type FieldProps = {
  fieldName: string;
  dateReported: string;
  growerName: string;
  farmName: string;
};
const Snapshot = () => {
  const dispatch = useDispatch();
  // Old
  const toggleData = useSelector((state: RootState) => state?.headerDataStore?.isToggle);
  const snapshotDisplayInfo = useSelector(selectDisplayableSnapshotInfo);
  const snapshotInputInfo = useSelector(selectInputSnapshotInfo);

  // New
  const nutrientData = useSelector(selectNutrientData);
  const selectedNutrients = useSelector(selectSelectedNutrients);
  const currentRange = useSelector(getCurrentRange);
  const currentColor = useSelector(selectCurrentColor);
  const selectedRange = useSelector(selectSelectedRange);
  const customRange = useSelector<RootState, boolean>((state) =>
    isCustomRange(state, selectedRange),
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [fieldInfo, setFieldInfo] = useState<DisplaySnapshotInfo>({
    dateReported: snapshotInputInfo.dateReported,
    farmName: snapshotInputInfo.farmName,
    fieldName: snapshotInputInfo.fieldName,
    growerName: snapshotInputInfo.growerName,
  });

  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('snapshot-document-container');
      container?.classList.add('hidden');
    }, 1000);
  }, []);

  const handleEdit = () => {
    setModalOpen(true);
  };

  const handleCancelInfo = () => {
    setModalOpen(false);
  };

  const handleSubmitInfo = () => {
    setModalOpen(false);
    dispatch(
      updateSnapshotInfo({
        ...fieldInfo,
        dateReported: parseLabDate(fieldInfo.dateReported, 'input'),
      }),
    );
  };

  const handleRangeUpdate = (nutrient: SoilNutrient, newRange: NutrientRange) => {
    const updatedRange = { ...currentRange };
    updatedRange.rangeMapping[nutrient] = newRange;
    if (!customRange) {
      const newId = uniqueId();
      updatedRange.name = `${updatedRange.name} Copy`;
      dispatch(upsertCustomRange({ id: newId, range: updatedRange }));
      dispatch(setSelectedRange(newId));
    } else {
      dispatch(upsertCustomRange({ id: selectedRange, range: updatedRange }));
    }
  };

  const infoUpdate = (key: keyof FieldProps, value: string): void => {
    setFieldInfo({
      ...fieldInfo,
      [key]: value,
    });
  };

  const pageData = chunk(
    selectedNutrients.filter(
      (nutrient) => !isNil(nutrientData[nutrient]) && !isNil(currentRange.rangeMapping[nutrient]),
    ),
    9,
  );

  const modalAction: ModalAction[] = [
    {
      color: 'primary',
      variant: 'outlined',
      buttonText: 'Cancel',
      footerBtnClick: handleCancelInfo,
    },
    {
      color: 'primary',
      variant: 'contained',
      buttonText: 'Save',
      footerBtnClick: handleSubmitInfo,
    },
  ];

  return (
    <>
      <div className="flex flex-col items-center bg-white mt-7 overflow-hidden w-[1302px] mx-auto">
        {modalOpen && (
          <Modal title="Edit Field Info" onClose={() => setModalOpen(false)} actions={modalAction}>
            <div className="flex flex-col justify-center items-center">
              <InputField
                label="Field Name"
                type="text"
                name="fieldName"
                value={fieldInfo.fieldName}
                onChange={(value) => infoUpdate('fieldName', value)}
              />
              <InputField
                label="Date Reported"
                type="date"
                name="dateReported"
                value={fieldInfo.dateReported}
                onChange={(value) => infoUpdate('dateReported', value)}
              />
              <InputField
                label="Grower Name"
                type="text"
                name="growerName"
                value={fieldInfo.growerName}
                onChange={(value) => infoUpdate('growerName', value)}
              />
              <InputField
                label="Farm Name"
                type="text"
                name="farmName"
                value={fieldInfo.farmName}
                onChange={(value) => infoUpdate('farmName', value)}
              />
            </div>
          </Modal>
        )}
        <div className="w-full">
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-between p-12">
              <SoilSnapshot />
              <Powered />
            </div>
            <InfoCard
              title={snapshotDisplayInfo.fieldName}
              date={snapshotDisplayInfo.dateReported}
              name={snapshotDisplayInfo.growerName}
              farmName={snapshotDisplayInfo.farmName}
              handleEdit={handleEdit}
            />
          </div>
          <div id="content" className="flex flex-wrap	p-8 gap-8">
            {selectedNutrients.map((nutrient) => {
              const data = nutrientData[nutrient];
              const range = currentRange.rangeMapping[nutrient];
              if (isNil(data) || isNil(range)) {
                return null;
              } else if (toggleData) {
                return (
                  <RangeCustomisation
                    key={nutrient}
                    nutrient={nutrient}
                    rangeColors={currentColor.colors}
                    rangeData={range}
                    onChange={handleRangeUpdate}
                  />
                );
              } else {
                return (
                  <GaugeContainer
                    key={nutrient}
                    description={getNutrientDescription(nutrient, data, range)}
                    gaugecolors={currentColor.colors}
                    nutrient={nutrient}
                    nutrientData={data}
                    nutrientRange={range}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      {/* 
        Split the gauges up manually so they can be placed on individual pages in the PDF conversion.
        A better solution would be to use the browser print for actual printing (so alt+p works) and to
        find a pdf solution that would respect print media queries.
        Currently everything needs to be scaled down with print media queries to fit in the viewport and the PDF
        solution we are using wouldn't even support that print styling so this is fine for now.
       */}
      <div id="snapshot-document-container">
        {pageData.map((pageChunk: SoilNutrient[], index: number) => {
          return (
            <div
              id={`snapshot-document-page-${index + 1}`}
              className="flex flex-col items-center bg-white mt-7 overflow-hidden w-[1302px] mx-auto min-h-[1850px]"
            >
              <div className="flex flex-col justify-between flex-1 w-full">
                {index === 0 ? (
                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between p-12">
                      <SoilSnapshot />
                      <Powered />
                    </div>
                    <InfoCard
                      title={snapshotDisplayInfo.fieldName}
                      date={snapshotDisplayInfo.dateReported}
                      name={snapshotDisplayInfo.growerName}
                      farmName={snapshotDisplayInfo.farmName}
                      handleEdit={handleEdit}
                    />
                  </div>
                ) : null}
                <div id="content" className="flex flex-wrap	p-8 gap-8">
                  {pageChunk.map((nutrient: SoilNutrient) => {
                    const data = nutrientData[nutrient];
                    const range = currentRange.rangeMapping[nutrient];
                    if (isNil(data) || isNil(range)) {
                      return null;
                    }
                    return (
                      <GaugeContainer
                        key={nutrient}
                        description={getNutrientDescription(nutrient, data, range)}
                        gaugecolors={currentColor.colors}
                        nutrient={nutrient}
                        nutrientData={data}
                        nutrientRange={range}
                      />
                    );
                  })}
                </div>
                <div className="text-center text-2xl text-primary-700 font-bold pb-2">
                  deveron.com
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Snapshot;
