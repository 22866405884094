import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../../../assets/Icon';
import Button from '../../../../components/Button';
import ColorOption from '../../../../components/ColorOption';
import Hero from '../../../../components/Hero';
import { DEFAULT_COLOR_MAPPING } from '../../../../constants/color.constants';
import { CUSTOM_HERO_CONSTANTS } from '../../../../constants/heroConstants';
import { selectSelectedColor, setSelectedColor } from '../../../../features/customizeData.slice';
import BackButton from '../BackButton';

const ColorSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedColor = useSelector(selectSelectedColor);

  const handleSelect = (id: string) => {
    dispatch(setSelectedColor(id));
  };

  const handleSave = () => {
    navigate('/snapshot');
  };

  return (
    <div className="w-full p-4">
      <BackButton />
      <div className="max-w-[1024px] mx-auto">
        <Hero
          title={CUSTOM_HERO_CONSTANTS.COLOR.TITLE}
          text={CUSTOM_HERO_CONSTANTS.COLOR.DESCRIPTION}
        />
        <div className="flex items-center justify-center gap-[20px]">
          {Object.entries(DEFAULT_COLOR_MAPPING).map(([id, data]) => (
            <ColorOption
              key={id}
              id={id}
              data={data}
              selected={selectedColor === id}
              onSelect={handleSelect}
            />
          ))}
        </div>
        <div className="flex items-center justify-between gap-11 my-14 mx-4">
          <div>
            <Button
              variant="text"
              color="primary"
              leftIcon={<ArrowLeft />}
              btnClick={() => navigate('/customize/range')}
            >
              BACK: RANGE
            </Button>
          </div>
          <div className="flex gap-11">
            <Button
              className="w-[161px] h-[43px] font-roboto text-sm	font-bold"
              variant="contained"
              color="primary"
              btnClick={handleSave}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ColorSelection;
