import { useState } from 'react';
import Button from '../../../components/Button';

import FileInputButton from '../../../components/FileInputButton';
import Hero from '../../../components/Hero';

export type FixMidwestProps = {};

const FixMidwest: React.FunctionComponent<FixMidwestProps> = (
  props: FixMidwestProps,
): JSX.Element => {
  const [fixedText, setFixedText] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const onFileDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result as string;
      let fixedText = text.replaceAll('"', '');
      fixedText = fixedText.replaceAll(',\n', ',\r\n');
      setFixedText(fixedText);
      setDownloadUrl(window.URL.createObjectURL(new Blob([fixedText], { type: 'text/csv' })));
    };
    if (input.files) {
      reader.readAsText(input.files[0]);
      setFileName(`Fixed_${input.files[0].name}`);
    }
  };

  return (
    <div className="pt-12 container mx-auto">
      <Hero
        title="Clean Up Midwest Labs CSV"
        text="Fixes formatting errors in the CSV files from Midwest Labs"
        primary
      />
      <div className="flex flex-col justify-center items-center">
        <ol>
          <li>1. Click Choose File</li>
          <li>2. Select the desired Midwest Lab CSV file</li>
          <li>3. The fixed contents of the file will be displayed with a Download button</li>
          <li>4. Click Download and save the new file</li>
          <li>5. Repeat for all files needed, then go to Soil Snapshot by clicking the logo</li>
        </ol>
        <div className="flex flex-row gap-8 items-center justify-between mb-8">
          <FileInputButton
            actions={[{ color: 'primary', variant: 'contained', buttonText: 'choose file' }]}
            handleFileInputButton={onFileDrop}
          />
          {fixedText !== '' && (
            <>
              <a href={downloadUrl} download={fileName}>
                <Button variant="contained" color="primary">
                  Download
                </Button>
              </a>
            </>
          )}
        </div>
      </div>
      {fixedText !== '' && (
        <>
          <div className="w-full resize-none h-[400px]">{fixedText}</div>
        </>
      )}
    </div>
  );
};

export default FixMidwest;
