type InputFieldProps = {
  label: string;
  type: string;
  name: string;
  value?: string;
  onChange: (value: string) => void;
};

const InputField = ({
  label = 'field',
  type = 'text',
  name = 'field',
  value,
  onChange,
}: InputFieldProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className="flex flex-col mb-6">
      <label className="text-sm font-normal text-neutral-700 mb-1">{label}</label>
      <div className="h-10 w-96 border border-neutral-300 rounded px-4 focus-within:border-2 focus-within:border-primary-500">
        <input
          value={value}
          className="bg-transparent h-full w-full focus:outline-none"
          type={type}
          name={name}
          onChange={changeHandler}
        />
      </div>
    </div>
  );
};

export default InputField;
