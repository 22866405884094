import classNames from 'classnames';
import * as React from 'react';
import { Bin, Error } from '../../assets/Icon';
import FileInputButton from '../FileInputButton';
import Text from '../Text';

type SelectedFileDisplayProps = {
  fileIcon: JSX.Element;
  file?: File;
  fileRemove: () => void;
  binIcon?: boolean;
  errorText?: string;
  errorType?: string;
  className?: string;
  validator?: any;
  handleFileInput?: (files: any) => void;
};

const SelectedFileDisplay: React.FC<SelectedFileDisplayProps> = ({
  fileIcon,
  file,
  fileRemove,
  binIcon,
  errorText,
  errorType,
  className,
  validator,
  handleFileInput,
}) => {
  const handleOnChange = (event: any) => {
    const files = (event.target as HTMLInputElement).files || [];
    if (handleFileInput) {
      handleFileInput(Array.from(files));
    }
  };

  const [color, setColor] = React.useState('black');
  const displayClass = classNames(
    'w-40 truncate text-base mt-1 ml-2',
    {
      'text-blue-400': !errorType,
    },
    className,
  );
  return (
    <>
      {validator.status ? (
        <div className="border border-dashed relative border-zinc-650 w-[748px] flex flex-col justify-center items-center">
          <div className="border mt-10 mb-2 mx-5 py-4 px-8 rounded-md w-11/12 flex items-center justify-between">
            <span className="flex items-center">
              <span className="mr-2">{<Error />}</span>
              <span className={displayClass}>{file?.name} </span>
              <span className="text-red-600 px-4 font-normal">{errorText}</span>
            </span>
            <span className="flex items-center z-10">
              {binIcon && (
                <div
                  className="border ml-12 rounded p-2 cursor-pointer z-10 hover:bg-red-600"
                  onClick={() => fileRemove()}
                  onMouseOver={() => setColor('white')}
                  onMouseLeave={() => setColor('black')}
                >
                  <Bin color={color} />
                </div>
              )}
            </span>
          </div>
          <div className="self-start mx-8 text-red-600">{validator.message}</div>
          <div className="flex items-center text-base text-neutral-600 mt-8 self-end mr-8">
            <Text className="mr-4 mb-2 text-neutral-600">
              To upload a different file, drag and drop your CSV here or
            </Text>
            <FileInputButton
              className="mb-10 mt-6"
              actions={[{ color: 'primary', variant: 'outlined', buttonText: 'choose file' }]}
              handleFileInputButton={handleOnChange}
            />
          </div>
        </div>
      ) : (
        <div className="border border-dashed relative border-zinc-650 w-[748px] flex flex-col justify-center items-center">
          <div className="border my-10 mx-5 py-4 px-8 rounded-md w-11/12 flex items-center justify-between">
            <span className="flex items-center">
              <span className="mr-2">{fileIcon}</span>
              <span className={displayClass}>{file?.name} </span>
              <span className="text-red-600 px-4 font-normal">{errorText}</span>
            </span>
            <span className="flex items-center z-10">
              {binIcon && (
                <div
                  className="border ml-12 rounded p-2 cursor-pointer z-10 hover:bg-red-600"
                  onClick={() => fileRemove()}
                  onMouseOver={() => setColor('white')}
                  onMouseLeave={() => setColor('black')}
                >
                  <Bin color={color} />
                </div>
              )}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedFileDisplay;
